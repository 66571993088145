import { theme } from '@ui/style'

const Icon32MegaMy = ({ color }: SVGComponentProps) => {
	const colorValue = color || theme.color.colBlack
	return (
		<svg
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="16.5" cy="10.5" r="4" stroke={color} strokeWidth="1.8" />
			<path
				d="M6.5 22C6.5 19.7909 8.29086 18 10.5 18H22.5C24.7091 18 26.5 19.7909 26.5 22V25H6.5V22Z"
				stroke={color}
				strokeWidth="1.8"
			/>
		</svg>
	)
}

export default Icon32MegaMy
