'use client'

import UIFlex from '@components/ui/UIFlex'
import zIndexes from '@ui/style/zIndexes.theme'
import color from '@ui/style/color.theme'
import { useEffect } from 'react'
import SettingHeader from './SettingHeader'
import SettingBody from './SettingBody'
import { useCloseHeaderState, useHeaderStates } from '../store/store'

const Setting = () => {
	const [headerStates] = useHeaderStates()
	useEffect(() => {
		if (headerStates.settingOpen) {
			document.body.classList.add('scroll-lock-all')
		}
		return () => {
			if (!headerStates.megaMenuOpen && !headerStates.alarmOpen) {
				document.body.classList.remove('scroll-lock-all')
			}
		}
	}, [headerStates.settingOpen, headerStates.megaMenuOpen, headerStates.alarmOpen])
	const handleSettingClose = useCloseHeaderState('settingOpen')
	return (
		headerStates.settingOpen && (
			<UIFlex
				position="fixed"
				zIndex={zIndexes.fullModal + 1}
				bgcolor={color.colWhite}
				top={0}
				left={0}
				right={0}
				bottom={0}
				sx={{
					'#wrapper > header:has(&)': {
						zIndex: 3100,
					},
				}}
			>
				<SettingHeader onClose={handleSettingClose} />
				<SettingBody />
			</UIFlex>
		)
	)
}

export default Setting
