import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'
import { NotificationNoticeNewNoticeResponse, notificationNoticeNewNoticeQueryKey } from './types'

import { useHeaderStates } from '@components/layout/Header/store/store'
import { APIResultCode } from 'src/shared/code'

const path = '/restapi/v1/get/notification/notice/new-notice'
export const getNotificationNoticeNewNotice = async (cookieHeader?: string) => {
	return fetchAPI({ path, cookieHeader })
}

export const useNotificationNoticeNewNoticeQuery = (
	input?: CommonUseQueryInput<NotificationNoticeNewNoticeResponse, undefined>,
) => {
	const [hasError, setHasError] = useState(false)
	// const { setAuth } = useAuthControl()
	const [headerStates] = useHeaderStates()

	const { data, ...rest } = useQuery<NotificationNoticeNewNoticeResponse>({
		queryKey: [notificationNoticeNewNoticeQueryKey],
		queryFn: async () => {
			try {
				const res = await getNotificationNoticeNewNotice()
				// 로그인 풀린 경우 에러 처리
				if (!res || res.resultCode !== APIResultCode.SUCCESS) {
					setHasError(true)
					return null
				}

				return res
			} catch (e) {
				setHasError(true)
				console.log(`Error occured in ${path}`, e)
				return null
			}
		},
		meta: { skipGlobalErrorHandler: true },
		retry: 0,
		refetchOnWindowFocus: !headerStates.alarmOpen && !hasError,
		...(!headerStates.alarmOpen && { refetchInterval: hasError ? false : 60000 }),
		...input?.opt,
	})

	return { data, ...rest }
}
