import UIFlex from '@components/ui/UIFlex'
import { SlideAnchorButton } from '@components/ui/UITab/styled'
import { styled } from '@mui/joy'
import { NoticeType } from '@services/api/Notification/Notice/List/types'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import React from 'react'

type NotificationTabProps = {
	noticeType: NoticeType | null
	setNoticeType: React.Dispatch<React.SetStateAction<NoticeType | null>>
}

const TabItem = styled(SlideAnchorButton)(() => ({
	fontSize: `${pxToRem(13)}!important`,
	lineHeight: `${pxToRem(16)}!important`,
	paddingTop: `${pxToRem(8)}!important`,
	paddingBottom: `${pxToRem(7)}!important`,
	paddingLeft: `${pxToRem(8)}!important`,
	paddingRight: `${pxToRem(8)}!important`,
	marginBottom: '-1px',
}))

const tabs = [
	{ label: '전체', value: null },
	{ label: '뉴스', value: 'NEWS' },
	{ label: '구독', value: 'SUBSCRIBE' },
	{ label: '활동', value: 'ACTION' },
	{ label: '시청예약', value: 'LIVE_RESERVE' },
]

const NotificationTab = ({ noticeType, setNoticeType }: NotificationTabProps) => {
	return (
		<UIFlex
			flexDirection="row"
			borderBottom={`1px solid ${color.colGray2}`}
			pr={{
				xs: pxToRem(8),
				md: 0,
			}}
			sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(8.5) } }}
		>
			{tabs.map((tab, idx) => (
				<TabItem
					key={`${tab.value}`}
					aria-selected={noticeType === tab.value}
					onClick={() => setNoticeType((tab.value as NoticeType) ?? null)}
				>
					{tab.label}
				</TabItem>
			))}
		</UIFlex>
	)
}

export default NotificationTab
