'use client'

import UIDivider from '@components/ui/UIDivider'
import UILink from '@components/ui/UILink'
import { Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import { HierarchyMenuResponseVo } from '@schemas/non-auth'
import { Fragment, useCallback } from 'react'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { MegaItemTitle } from './MegaMenuItem'
import { useCloseHeaderState } from '../../store/store'

interface HighlightMenuProps {
	// liveMenu?: HierarchyMenuResponseVo
	menu: Array<HierarchyMenuResponseVo>
}
const HighlightMenu = ({ menu }: HighlightMenuProps) => {
	const { darkMode } = useDarkModeControl()
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')
	const moHighlight = menu.filter((item) => item.isMoHighlight)

	const moFindIndex = useCallback(
		(item: HierarchyMenuResponseVo) => {
			return moHighlight.findIndex((moItem) => moItem.menuIdx === item.menuIdx)
		},
		[moHighlight],
	)
	return (
		<Grid
			container
			fontSize={0}
			columnSpacing={{ xs: pxToRem(16), md: 0 }}
			sx={{
				'&>*:not(:last-child)': {
					marginBottom: {
						md: pxToRem(14),
						lg: pxToRem(16),
					},
				},
			}}
		>
			{menu.map((item) => {
				const moIndex = moFindIndex(item)
				return (
					<Fragment key={item.menuIdx}>
						<Grid
							xs={6}
							md={12}
							display={{
								xs: item.isMoHighlight ? 'block' : 'none',
								md: item.isPcHighlight ? 'block' : 'none',
							}}
						>
							<UILink link={item.linkUrlInfo} onClick={handleMegaMenuClose}>
								<MegaItemTitle darkMode={darkMode}>{item.menuName}</MegaItemTitle>
							</UILink>
						</Grid>
						{moIndex >= 0 &&
							moIndex % 2 === 1 &&
							moHighlight.length - 1 !== moIndex && (
								<UIDivider
									orientation="horizontal"
									sx={{
										display: {
											xs: 'block',
											md: 'none',
										},
										marginY: pxToRem(20),
										background: darkMode ? '#303030' : color.colGray2,
									}}
								/>
							)}
					</Fragment>
				)
			})}
		</Grid>
	)
}

export default HighlightMenu
