'use client'

import UIButton from '@components/ui/UIButton'
import UIDivider from '@components/ui/UIDivider'
import UIFlex from '@components/ui/UIFlex'
import UIIconButton from '@components/ui/UIIconButton'
import UIImage from '@components/ui/UIImage'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useMedia } from '@hooks/useMedia'
import { Box } from '@mui/joy'
import { SnsResponseVo } from '@schemas/non-auth'
import { useNotificationNoticeNewNoticeQuery } from '@services/api/Notification/Notice/NewNotice'
import { useAuthControl } from '@store/auth'
import { useIsAppControl } from '@store/isApp'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { isSafari, osName } from 'react-device-detect'
import LogoAndroid from 'src/assets/icons/IconAndroid'
import LogoIos from 'src/assets/icons/IconIOS'
import { useCloseHeaderState } from '../../store/store'

export interface MegaMenuBottomsProps {
	snsList: Array<SnsResponseVo>
}

const MegaMenuBottoms = ({ snsList }: MegaMenuBottomsProps) => {
	const { auth } = useAuthControl()
	const { darkMode } = useDarkModeControl()
	const { appInfo, isApp } = useIsAppControl()
	const pathname = usePathname()
	const [currentPath, setCurrentPath] = useState<string>()
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')
	const appLink = {
		A: process.env.NEXT_PUBLIC_APP_LINK_ANDROID,
		I: process.env.NEXT_PUBLIC_APP_LINK_IOS,
	}
	const isMoDevice = useMedia('md')
	const isIOS = osName === 'iOS' || isSafari || appInfo?.DType === 'I'
	const failSnsList = Object.prototype.hasOwnProperty.call(snsList, 'dehydratedState')
	const { auth: isLoggedIn, setAuth } = useAuthControl()
	const { data: newNotice, refetch: refetchNewNotice } = useNotificationNoticeNewNoticeQuery({
		opt: { enabled: isLoggedIn, meta: { skipGlobalErrorHandler: true } },
	})
	const LogButtonSx = {
		position: 'static',
		fontSize: pxToRem(14),
		lineHeight: pxToRem(20),
		color: color.colGray5,
		paddingLeft: 0,
		fontWeight: 500,
		'&:hover, &:active': {
			color: color.colGray5,
		},
		[theme.mediaQueries.under.md]: {
			position: 'absolute',
			justifyContent: 'center',
			top: '100%',
			left: 0,
			right: 0,
			zIndex: 5,
			height: '48px',
			background: '#F6F6F6',
			margin: `0 ${pxToRem(-20)}`,
			fontSize: pxToRem(13),
			lineHeight: pxToRem(16),
		},
	}
	const handleLogout = () => {
		const isMypage = pathname.includes('/mypage')
		const targetUrl = isMypage ? encodeURIComponent(window.location.origin) : currentPath
		window.location.href = `${process.env.NEXT_PUBLIC_AUTH_LOGOUT_URL}?targetUrl=${targetUrl}`
	}
	useEffect(() => {
		setCurrentPath(encodeURIComponent(window.location.href))
	}, [pathname])

	return (
		<UIFlex
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			px={{ md: pxToRem(24), lg: 0 }}
		>
			{!isApp &&
				(isLoggedIn ? (
					<UIButton variant="text" onClick={handleLogout} sx={LogButtonSx}>
						로그아웃
					</UIButton>
				) : (
					<UILink
						href={`${process.env.NEXT_PUBLIC_AUTH_LOGIN_URL}?targetUrl=${currentPath}`}
						sx={LogButtonSx}
					>
						로그인
					</UILink>
				))}
			<UIFlex
				flexDirection="row"
				justifyContent={{ xs: 'space-between', md: 'flex-end' }}
				flexGrow={1}
				// 24
			>
				{!isMoDevice
					? !isApp && (
							<>
								<UIFlex direction="row">
									<UILink
										href={appLink.I}
										flexShrink={0}
										target="_blank"
										onClick={handleMegaMenuClose}
									>
										<UIIconButton
											sx={{
												width: pxToRem(28),
												height: pxToRem(28),
												minWidth: 'unset',
												minHeight: 'unset',
												padding: 0,
											}}
										>
											<LogoIos color={darkMode ? color.colGray1 : '#000'} />
											<UIText readonly>아이폰 다운로드 링크 바로가기</UIText>
										</UIIconButton>
									</UILink>
									<UILink
										href={appLink.A}
										flexShrink={0}
										target="_blank"
										ml={pxToRem(16)}
										onClick={handleMegaMenuClose}
									>
										<UIIconButton
											sx={{
												width: pxToRem(28),
												height: pxToRem(28),
												minWidth: 'unset',
												minHeight: 'unset',
												padding: 0,
											}}
										>
											<LogoAndroid />
											<UIText readonly>
												안드로이드 다운로드 링크 바로가기
											</UIText>
										</UIIconButton>
									</UILink>
								</UIFlex>
								<UIDivider
									orientation="vertical"
									sx={{
										display: { xs: 'none', md: 'block' },
										background: darkMode ? '#303030' : color.colGray2,
										marginLeft: {
											md: pxToRem(24),
										},
									}}
								/>
							</>
					  )
					: !isApp && (
							<>
								<UILink
									href={appLink[isIOS ? 'I' : 'A']}
									onClick={handleMegaMenuClose}
								>
									<UIText
										fontSize={{
											xs: pxToRem(14),
											md: pxToRem(13),
										}}
										lineHeight={pxToRem(20)}
										fontWeight={500}
										color={darkMode ? color.colGray1 : ''}
									>
										JTBC APP
									</UIText>
								</UILink>
								<UIDivider
									orientation="vertical"
									sx={{
										display: { xs: 'none', md: 'block' },
										background: darkMode ? '#303030' : color.colGray2,
										marginLeft: {
											md: pxToRem(24),
										},
									}}
								/>
							</>
					  )}

				{!failSnsList && snsList.length > 0 && (
					<UIFlex
						flexDirection="row"
						alignItems="center"
						ml={isApp ? 'auto' : pxToRem(24)}
						mr={isApp ? 'auto' : ''}
						sx={{
							'&>*:not(:first-child)': {
								marginLeft: pxToRem(16),
							},
						}}
					>
						{snsList?.map((item) => (
							<UILink
								key={`sns-${item.snsIdx}`}
								link={item.linkUrlInfo}
								flexShrink={0}
								onClick={handleMegaMenuClose}
							>
								<Box width={pxToRem(28)} height={pxToRem(28)}>
									<UIImage
										ratio={1}
										src={
											darkMode ? item.snsIconBlackImgUrl : item.snsIconImgUrl
										}
										alt={`${item.snsChannelName} 아이콘`}
									/>
								</Box>
								<UIText readonly>{item.snsChannelName} 바로가기</UIText>
							</UILink>
						))}
					</UIFlex>
				)}
			</UIFlex>
		</UIFlex>
	)
}

export default MegaMenuBottoms
