const Icon24Delete = ({ className }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<rect x="7" y="7" width="10" height="11" stroke="#242424" strokeWidth="1.8" />
			<line x1="13.4" y1="9.5" x2="13.4" y2="15.5" stroke="#242424" strokeWidth="1.8" />
			<line x1="10.4" y1="9.5" x2="10.4" y2="15.5" stroke="#242424" strokeWidth="1.8" />
			<line x1="19" y1="6.9" x2="5" y2="6.9" stroke="#242424" strokeWidth="1.8" />
			<line x1="15" y1="3.9" x2="9" y2="3.9" stroke="#242424" strokeWidth="1.8" />
		</svg>
	)
}

export default Icon24Delete
