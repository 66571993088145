'use client'

import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import zIndexes from '@ui/style/zIndexes.theme'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import { useEffect, useState } from 'react'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import MegaMenuList, { MegaMenuListProps } from './MegaMenuList'
import MegaMenuMobileUtilities from './MegaMenuMobile/MegaMenuMobileUtilties'
import MegaMenuBottoms, { MegaMenuBottomsProps } from './MegaMenuBottoms'
import MegaMenuMobileHeader from './MegaMenuMobile/MegaMenuMobileHeader'
import { useHeaderStates } from '../store/store'
import { theme } from '@ui/style'

export type MegaMenuProps = MegaMenuListProps & MegaMenuBottomsProps
const MegaMenu = ({ menu, snsList }: MegaMenuProps) => {
	const { darkMode } = useDarkModeControl()
	const [headerStates, setHeaderStates] = useHeaderStates()
	const headerHeight = 144
	const headerOffset = 100

	useEffect(() => {
		if (headerStates.megaMenuOpen) {
			document.body.classList.add('scroll-lock-all')
		} else {
			document.body.classList.remove('scroll-lock-all')
		}
		return () => {
			document.body.classList.remove('scroll-lock-all')
		}
	}, [headerStates.megaMenuOpen])

	return (
		headerStates.megaMenuOpen && (
			<UIFlex
				position={{ xs: 'fixed', md: 'absolute' }}
				left="0"
				top={{ xs: 0, md: '100%' }}
				width="100%"
				height={{ xs: '100%', md: 'auto' }}
				maxHeight={{ md: `calc(100vh - ${headerHeight + headerOffset}px)` }}
				bgcolor={darkMode ? color.colBlack : color.colWhite}
				zIndex={{ xs: zIndexes.fullModal, md: -2 }}
				boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.10)"
			>
				{/* 모바일용 헤더 */}
				<MegaMenuMobileHeader />
				<UIContainer
					className="mega-menu"
					overflow="auto"
					flexGrow={1}
					sx={{
						overscrollBehavior: 'contain',
						[theme.breakpoints.only('md')]: {
							padding: 0,
						},
					}}
				>
					{/* 모바일용 버튼영역 */}
					<Box display={{ xs: 'block', md: 'none' }}>
						<MegaMenuMobileUtilities />
					</Box>
					{/* 메뉴 */}
					<Box mt={{ xs: pxToRem(12), md: 0 }}>
						<MegaMenuList menu={menu} />
					</Box>
					<UIDivider
						orientation="horizontal"
						sx={{
							display: { xs: 'none', md: 'block' },
							background: darkMode ? '#303030' : color.colGray2,
						}}
					/>
					{/* 하단 */}
					<Box
						py={{ xs: pxToRem(20), md: pxToRem(14), lg: pxToRem(16) }}
						position="relative"
					>
						<MegaMenuBottoms snsList={snsList} />
					</Box>
				</UIContainer>
			</UIFlex>
		)
	)
}

export default MegaMenu
