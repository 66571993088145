import React, { useEffect } from 'react'
import { Box } from '@mui/joy'

import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useCloseHeaderState, useHeaderStates } from '../store/store'

import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'
import zIndexes from '@ui/style/zIndexes.theme'
import { Icon32Close } from 'src/assets/icons/Icon32Close'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import UIContainer from '@components/ui/UIContainer'
import SearchMobileHeader from './SearchMobileHeader'

const SearchLayout = ({
	articleList,
	children,
}: {
	articleList: React.ReactNode
	children: React.ReactNode
}) => {
	const { darkMode } = useDarkModeControl()
	const [headerStates] = useHeaderStates()
	const headerHeight = 144
	const headerOffset = 100
	useEffect(() => {
		if (headerStates.searchOpen) {
			document.body.classList.add('scroll-lock-all')
		} else {
			document.body.classList.remove('scroll-lock-all')
		}
		return () => {
			document.body.classList.remove('scroll-lock-all')
		}
	}, [headerStates.searchOpen])
	const handleSearchClose = useCloseHeaderState('searchOpen')

	return (
		headerStates.searchOpen && (
			<UIFlex
				position={{ xs: 'fixed', md: 'absolute' }}
				left="0"
				top={{ xs: 0, md: '100%' }}
				width="100%"
				height={{ xs: '100%', md: 'auto' }}
				maxHeight={{ md: `calc(100vh - ${headerHeight + headerOffset}px)` }}
				overflow="auto"
				bgcolor={darkMode ? color.colBlack : color.colWhite}
				zIndex={{ xs: zIndexes.fullModal + 1, md: -1 }}
				boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.10)"
			>
				<SearchMobileHeader />
				<UIContainer
					display="flex"
					flexDirection="column"
					pt={{ xs: pxToRem(17), md: pxToRem(24), xl: pxToRem(32) }}
					pb={{ xs: pxToRem(60), md: pxToRem(48) }}
					alignItems="center"
					overflow="auto"
				>
					<UIFlex
						width="100%"
						maxWidth={{ xs: '100%', xl: '800px' }}
						flex="none"
						sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(32) } }}
					>
						<UIFlex
							sx={{
								'&> *:not(:first-child)': {
									marginTop: {
										xs: pxToRem(16),
										md: pxToRem(20),
										xl: pxToRem(24),
									},
								},
							}}
						>
							<UIFlex
								flexDirection="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<UIText
									fontSize={{ xs: pxToRem(24), md: pxToRem(26) }}
									lineHeight={pxToRem(36)}
									fontWeight={700}
									color={darkMode ? color.colGray1 : color.colBlack}
								>
									어떤 기사를 찾으시나요?
								</UIText>
								<Box display={{ xs: 'none', md: 'block' }} mt={pxToRem(-4)}>
									<button type="button" onClick={handleSearchClose}>
										<Icon32Close
											color={darkMode ? color.colGray1 : color.colBlack}
										/>
										<UIText readonly>닫기</UIText>
									</button>
								</Box>
							</UIFlex>
							{children}
						</UIFlex>
						{articleList}
					</UIFlex>
				</UIContainer>
			</UIFlex>
		)
	)
}

export default SearchLayout
