import { useMutation } from '@tanstack/react-query'
import { fetchAPI } from '@utils/http/apiRequest'
import { NotificationNoticeRecentResponse, notificationNoticeRecentQueryKey } from './types'

const path = '/restapi/v1/delete/notification/notice/recent'
export const deleteNotificationNoticeRecent = async () => {
	return fetchAPI({
		path,
		method: 'POST',
	})
}

export const useNotificationNoticeRecentMutation = () => {
	const { data, ...rest } = useMutation<NotificationNoticeRecentResponse, Error>({
		mutationKey: [notificationNoticeRecentQueryKey],
		mutationFn: () => deleteNotificationNoticeRecent(),
	})
	return { data, ...rest }
}
