import Box from '@mui/joy/Box'
import { styled } from '@mui/joy/styles'
import pxToRem from '@utils/pxToRem'
import { background, height, paddingBottom } from 'styled-system'

export const HeaderBox = styled(Box)(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: theme.zIndexes.header,
	'&.liveTalkFixed': {
		[theme.breakpoints.down('lg')]: {
			position: 'fixed!important',
			top: 0,
			left: 0,
			right: 0,
			width: '100%',
			'.header__issueBox': {
				display: 'block!important',
			},
			'&.set-sticky': {
				'.header__navWrapper': {
					height: `${pxToRem(48)}!important`,
				},
			},
		},
	},
	'&.journalistTalkFixed': {
		[theme.breakpoints.down('md')]: {
			'.header__issueBox': {
				display: 'block!important',
			},
			'&.set-sticky': {
				'& + div': {
					marginTop: '0!important',
				},
			},
		},
	},
	'&.set-sticky': {
		position: 'sticky',
		'& + div': {
			marginTop: theme.pxToRem(44),
			[theme.breakpoints.up('md')]: {
				marginTop: theme.pxToRem(38),
			},
			[theme.breakpoints.up('lg')]: {
				marginTop: theme.pxToRem(60),
			},
		},
	},
}))
