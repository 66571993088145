import { theme } from '@ui/style'

export const Icon32Alert = ({ color, className }: SVGComponentProps) => {
	const iconColor = color || theme.color.colBlack
	return (
		<svg
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g id="Group">
				<path
					id="Ellipse 84"
					d="M8.16611 16.55C7.75272 16.996 7.5 17.593 7.5 18.2491C7.5 19.6298 8.61929 20.7491 10 20.7491C11.3807 20.7491 12.5 19.6298 12.5 18.2491C12.5 17.593 12.2473 16.996 11.8339 16.55"
					stroke={iconColor}
					strokeWidth="1.8"
				/>
				<path
					id="Union"
					d="M3 13.537L3.82923 13.8869L3.9 13.7191V13.537H3ZM1.75 16.5L0.920772 16.1502L0.3935 17.4H1.75V16.5ZM18.25 16.5V17.4H19.6065L19.0792 16.1502L18.25 16.5ZM17 13.537H16.1V13.7191L16.1708 13.8869L17 13.537ZM3.9 7.75C3.9 4.65721 6.40721 2.15 9.5 2.15V0.35C5.41309 0.35 2.1 3.66309 2.1 7.75H3.9ZM3.9 13.537V7.75H2.1V13.537H3.9ZM2.57923 16.8498L3.82923 13.8869L2.17077 13.1872L0.920772 16.1502L2.57923 16.8498ZM18.25 15.6H1.75V17.4H18.25V15.6ZM16.1708 13.8869L17.4208 16.8498L19.0792 16.1502L17.8292 13.1872L16.1708 13.8869ZM16.1 7.75V13.537H17.9V7.75H16.1ZM10.5 2.15C13.5928 2.15 16.1 4.65721 16.1 7.75H17.9C17.9 3.66309 14.5869 0.35 10.5 0.35V2.15ZM9.5 2.15H10.5V0.35H9.5V2.15Z"
					fill={iconColor}
				/>
			</g>
		</svg>
	)
}
