import Divider, { DividerProps } from '@mui/joy/Divider'
import UIFlex from '@components/ui/UIFlex'
import UILink, { UILinkProps } from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import UIIconButton from '@components/ui/UIIconButton'
import { styled } from '@mui/joy/styles'
import pxToRem from '@utils/pxToRem'

export const UtilBox = styled(UIFlex)(({ theme }) => ({
	flexShrink: 0,
	[theme.mediaQueries.over.lg]: {
		'&>*:not(:first-child)': {
			marginTop: pxToRem(12),
		},
	},
	'.set-sticky &>*:not(:first-child)': {
		marginTop: 0,
	},
	[theme.mediaQueries.only.sm]: {
		marginRight: theme.pxToRem(-8),
	},
	[theme.mediaQueries.under.md]: {
		'.set-sticky &': {
			paddingBottom: pxToRem(4),
		},
	},
}))

export const GroupUtilBox = styled(UIFlex)(({ theme }) => ({
	'&>*:not(:first-child)': {
		marginLeft: pxToRem(12),
	},
	'.set-sticky &': {
		display: 'none',
	},
}))

export const GroupUtilLink = styled(UILink)(({ theme }) => ({
	display: 'flex',
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	fontWeight: 500,
	color: theme.color.colGray5,
	whiteSpace: 'nowrap',
	paddingTop: theme.pxToRem(4),
	paddingBottom: theme.pxToRem(4),
}))

export const GroupUtilText = styled(UIText)(({ theme }) => ({
	display: 'flex',
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(16),
	fontWeight: 500,
	color: theme.color.colGray5,
	whiteSpace: 'nowrap',
	flexShrink: 0,
	paddingTop: theme.pxToRem(4),
	paddingBottom: theme.pxToRem(4),
}))
interface GroupUtilDividerProps extends DividerProps {
	darkMode?: boolean
}
export const GroupUtilDivider = styled(Divider, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<GroupUtilDividerProps>(({ theme, darkMode }) => ({
	width: '1px',
	blockSize: theme.pxToRem(12),
	backgroundColor: darkMode ? '#303030' : theme.color.colGray3,
	alignSelf: 'center',
}))

export const UtilIconButton = styled(UIIconButton)(({ theme }) => ({
	padding: 0,
	minWidth: theme.pxToRem(32),
	minHeight: theme.pxToRem(32),
}))

interface UtilAnchorProps extends UILinkProps {
	darkMode?: boolean
}
export const UtilAnchor = styled(UILink, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<UtilAnchorProps>(({ theme, darkMode }) => ({
	display: 'flex',
	alignItems: 'center',
	flexShrink: '0',
	padding: `${theme.pxToRem(6)} ${theme.pxToRem(12)}`,
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(20),
	fontWeight: 600,
	color: darkMode ? theme.color.colGray1 : theme.color.colDarkBlue,
	background: darkMode ? '#303030' : theme.color.colGray2,
	borderRadius: theme.pxToRem(16),
}))
