'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box, styled } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

export const ItemBox = styled(Box)({
	width: '100%',
	paddingTop: pxToRem(16),
	paddingBottom: pxToRem(15),
	borderBottom: `1px solid ${color.colGray3}`,
	'&:first-of-type': {
		borderTop: `1px solid ${color.colGray3}`,
		paddingTop: pxToRem(15),
	},
})

export const DescText = styled(UIText)({
	fontSize: pxToRem(14),
	lineHeight: pxToRem(12),
	fontWeight: 500,
	color: color.colGray4,
	marginTop: pxToRem(4),
})

export const TimeBox = styled(UIFlex)({
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
})

export const TimeTitle = styled(UIText)({
	fontSize: pxToRem(14),
	lineHeight: pxToRem(24),
	fontWeight: 600,
	color: color.colGray5,
})

export const TimeDesc = styled(Box)({
	fontSize: pxToRem(14),
	lineHeight: pxToRem(22),
	fontWeight: 500,
	color: color.colGray4,
})

export const UpdateText = styled(UIText)({
	fontSize: pxToRem(14),
	lineHeight: pxToRem(22),
	fontWeight: 500,
	color: color.colGray4,
})
