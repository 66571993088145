'use client'

import { Switch, SwitchProps, styled, Theme } from '@mui/joy'

export interface UISwitchState extends Omit<Partial<SwitchProps>, 'size' | 'color'> {
	size?: 'md' | 'lg' | 'xl'
	color?: 'default' | 'dark'
}
const UISwitchRoot = styled(Switch)(
	({ theme, ownerState }: { theme?: Theme; ownerState: UISwitchState }) => {
		const { size, color } = ownerState
		const convertStyleValue = (value: string | number) => {
			const numericValue = typeof value === 'string' ? parseFloat(value) : value
			return Number.isNaN(numericValue) ? value : theme?.pxToRem(numericValue)
		}
		const sizes = {
			xl: {
				trackWidth: 40,
				trackHeight: 24,
				thumbWidth: 20,
			},
			lg: {
				trackWidth: 36,
				trackHeight: 20,
				thumbWidth: 16,
			},
			md: {
				trackWidth: 24,
				trackHeight: 14,
				thumbWidth: 12,
			},
		}
		const { trackWidth, trackHeight, thumbWidth } = sizes[size || 'md']
		const styles = {
			minWidth: '28px',
			minHeight: '22px',
			alignItems: 'center',
			justifyContent: 'center',
			'--Switch-trackWidth': convertStyleValue(trackWidth),
			'--Switch-trackHeight': convertStyleValue(trackHeight),
			'--Switch-thumbWidth': convertStyleValue(thumbWidth),
			'--Switch-thumbSize': convertStyleValue(thumbWidth),

			'.MuiSwitch-track': {
				background: color === 'dark' ? theme?.color.colGray5 : theme?.color.colGray3,
			},
			'.MuiSwitch-thumb': {
				boxShadow: 'unset',
			},
			'&.MuiSwitch-root.Mui-checked .MuiSwitch-track': {
				background:
					color === 'dark'
						? theme?.color.colDarkmodePointBlue
						: theme?.color.colPointBlue,
			},
			'&.MuiSwitch-root:hover.Mui-checked .MuiSwitch-track': {
				background:
					color === 'dark'
						? theme?.color.colDarkmodePointBlue
						: theme?.color.colPointBlue,
			},
		}
		return styles
	},
)

export default UISwitchRoot
