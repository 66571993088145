const LogoAndroid = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Frame 427319472">
				<g id="google Icons">
					<path
						id="Vector"
						d="M14.1455 13.6867L8.34961 19.8375C8.34961 19.8375 8.34961 19.8398 8.34961 19.8409C8.52707 20.5087 9.13681 21.0001 9.86144 21.0001C10.1504 21.0001 10.4223 20.9216 10.6555 20.7851L10.6737 20.7737L17.1976 17.0095L14.1444 13.6855L14.1455 13.6867Z"
						fill="#EA4435"
					/>
					<path
						id="Vector_2"
						d="M20.0087 12.6393L20.003 12.6359L17.1864 11.0035L14.0137 13.8269L17.1977 17.0098L19.9996 15.3934C20.491 15.1283 20.8243 14.6107 20.8243 14.0135C20.8243 13.4163 20.4955 12.9055 20.0087 12.6393Z"
						fill="#F9BC15"
					/>
					<path
						id="Vector_3"
						d="M8.35034 8.1629C8.31508 8.29145 8.29688 8.42682 8.29688 8.5656V19.4351C8.29688 19.575 8.31508 19.7092 8.35034 19.8378L14.3453 13.8439L8.35034 8.1629Z"
						fill="#547DBF"
					/>
					<path
						id="Vector_4"
						d="M14.1896 14.0006L17.1893 11.002L10.6733 7.2241C10.4367 7.08191 10.1603 7 9.86453 7C9.1399 7 8.52902 7.49257 8.35156 8.16146C8.35156 8.16146 8.35156 8.16146 8.35156 8.1626L14.1896 14.0006Z"
						fill="#33A852"
					/>
				</g>
			</g>
		</svg>
	)
}

export default LogoAndroid
