import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import UIText, { UITextProps } from '@components/ui/UIText'
import { styled } from '@mui/joy/styles'
import { marginLeft, marginRight } from 'styled-system'
import pxToRem from '@utils/pxToRem'
import UILink from '@components/ui/UILink'

export const MenuList = styled(List)(({ theme }) => ({
	display: 'none',
	margin: 0,
	padding: 0,
	overflowY: 'auto',
	[theme.breakpoints.up('lg')]: {
		display: 'flex',
		'&>*:not(:first-child)': {
			marginLeft: pxToRem(20),
		},
	},
	[theme.breakpoints.up('xl')]: {
		'&>*:not(:first-child)': {
			marginLeft: pxToRem(24),
		},
	},
}))

export const MenuItem = styled(ListItem)(({ theme }) => ({
	margin: 0,
	padding: 0,
	fontsize: 0,
	minHeight: 0,
}))

interface MenuLinkProps extends UITextProps {
	darkMode?: boolean
}

export const MenuLink = styled(UILink, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<MenuLinkProps>(({ theme, darkMode }) => ({
	display: 'flex',
	alignItems: 'center',
	fontSize: theme.pxToRem(18),
	fontWeight: 700,
	lineHeight: theme.pxToRem(32),
	color: darkMode ? theme.color.colGray1 : '',
	'&.dotted::before': {
		content: '""',
		display: 'block',
		width: theme.pxToRem(6),
		height: theme.pxToRem(6),
		borderRadius: '100%',
		backgroundColor: theme.color.colLiveRed,
		marginRight: pxToRem(6),
	},
}))
