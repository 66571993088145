import { theme } from '@ui/style'

export const Icon32My = ({ color, fill, className }: SVGComponentProps) => {
	// const theme = useTheme()
	const iconColor = color || theme.color.colBlack
	const fillColor = fill || ''
	return (
		<svg
			width="22"
			height="21"
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g id="Group 48096225">
				<circle
					id="Ellipse 179"
					cx="11"
					cy="5.5"
					r="4"
					stroke={iconColor}
					strokeWidth="1.8"
					fill={fillColor}
				/>
				<path
					id="Rectangle 12649"
					d="M1 17C1 14.7909 2.79086 13 5 13H17C19.2091 13 21 14.7909 21 17V20H1V17Z"
					stroke={iconColor}
					strokeWidth="1.8"
					fill={fillColor}
				/>
			</g>
		</svg>
	)
}
