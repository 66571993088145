import { ArticleRankResponseVo } from '@schemas/non-auth'
import { QueryFunction } from '@tanstack/react-query'
import { ArticleListType } from '../types'

declare module '@utils/http/response' {
	interface AppResponseList {
		'/restapi/v1/get/article/rank-view': ArticleRankViewResponse
	}
}

export const articleRankViewQueryKey = ['@article/rank-view'] as const
export type ArticleRankViewQueryKey = readonly ['@article/rank-view', ArticleRankViewReqModel]

export type ArticleRankViewReqModel = {
	pageSize?: number
	searchDate?: string
	articleListType?: ArticleListType
}

export type ArticleRankViewResponse = CommonResponse<ArticleRankResponseVo>

export type ArticleRankViewQueryFunction = QueryFunction<
	ArticleRankViewResponse,
	ArticleRankViewQueryKey
>
