import { theme } from '@ui/style'

const Icon32MegaSearch = ({ color }: SVGComponentProps) => {
	const colorValue = color || theme.color.colBlack
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10.5" cy="10.5" r="5" stroke={colorValue} strokeWidth="1.5" />
			<path d="M19 19L14 14" stroke={colorValue} strokeWidth="1.5" />
		</svg>
	)
}

export default Icon32MegaSearch
