'use client'

import BrandMenu from '@components/layout/Header/BrandMenu'
import MegaMenu, { MegaMenuProps } from '@components/layout/Header/MegaMenu'
import Search from '@components/layout/Header/Search'
import useModal from '@components/layout/Modal'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import useIsMounted from '@hooks/useIsMounted'
import { Box } from '@mui/joy'
import { useNotificationNoticeListQuery } from '@services/api/Notification/Notice/List'
import { useNotificationNoticeNewNoticeQuery } from '@services/api/Notification/Notice/NewNotice'
import { useNotificationNoticeRecentReadMutation } from '@services/api/Notification/Notice/RecentRead'
import { useAuthControl } from '@store/auth'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { Icon16ArrowTiny } from 'src/assets/icons/Icon16ArrowTiny'
import { Icon32Alert } from 'src/assets/icons/Icon32Alert'
import Icon32DottedAlert from 'src/assets/icons/Icon32DottedAlert'
import { Icon32Menu } from 'src/assets/icons/Icon32Menu'
import { Icon32My } from 'src/assets/icons/Icon32My'
import { Icon32Search } from 'src/assets/icons/Icon32Search'
import NotificationPopover from '../../NotificationPopover'
import { useHeaderStates, useOpenHeaderState, useToggleSingleHeaderState } from '../../store/store'
import {
	GroupUtilBox,
	GroupUtilDivider,
	GroupUtilLink,
	GroupUtilText,
	UtilAnchor,
	UtilBox,
	UtilIconButton,
} from './styled'

export interface NavUtilsProps {
	auth?: boolean
	menuTop: MegaMenuProps['menu']
	snsList: MegaMenuProps['snsList']
	isApp: boolean
}
const NavUtils = ({ menuTop, snsList, isApp = false, auth = false }: NavUtilsProps) => {
	const { darkMode } = useDarkModeControl()
	const isMounted = useIsMounted()
	const pathname = usePathname()
	const [currentPath, setCurrentPath] = useState<string>()
	const { auth: isLoggedIn, setAuth } = useAuthControl()
	const [headerStates] = useHeaderStates()

	const divRef = useRef(null)
	useEffect(() => {
		if (headerStates.alarmOpen && divRef.current) {
			console.log('divRef.current', divRef.current)
		}
	}, [divRef, headerStates])

	const confirm = useModal()
	// API: 신규알림 존재여부 확인
	const { data: newNotice, refetch: refetchNewNotice } = useNotificationNoticeNewNoticeQuery({
		opt: { enabled: isLoggedIn, meta: { skipGlobalErrorHandler: true } },
	})

	// API: 알림목록 조회
	const {
		data,
		refetch: refetchNoticeList,
		isFetched: isNoticeListFetched,
	} = useNotificationNoticeListQuery({
		arg: { noticeType: undefined },
		opt: { enabled: false },
	})

	// API: 신규알림 읽음 처리
	const { mutateAsync: mutateRecentRead } = useNotificationNoticeRecentReadMutation()

	const activeSearch = useSearchParams().get('ui') === 'search'
	const handleSearchOpen = useOpenHeaderState('searchOpen')
	const toggleMegaMenu = useToggleSingleHeaderState('megaMenuOpen')
	const toggleBrandMenu = useToggleSingleHeaderState('brandMenuOpen')
	const toggleSearch = useToggleSingleHeaderState('searchOpen')
	const toggleAlarm = useToggleSingleHeaderState('alarmOpen')

	const handleLogout = () => {
		const isMypage = pathname.includes('/mypage')
		const targetUrl = isMypage ? encodeURIComponent(window.location.origin) : currentPath
		window.location.href = `${process.env.NEXT_PUBLIC_AUTH_LOGOUT_URL}?targetUrl=${targetUrl}`
	}

	const handleNotificationClick = async () => {
		// 로그인이 안 되어 있을 경우 로그인 페이지 모달 띄우기
		if (!isLoggedIn) {
			confirm.login()
			return
		}

		// 새 알림이 있을 경우 읽음처리 및 알림목록조회
		if (!isNoticeListFetched || newNotice?.data) {
			await mutateRecentRead().then((res) => {
				// 로그인 풀린 경우
				if (!res) {
					setAuth(false)
					return
				}

				refetchNewNotice()
				refetchNoticeList()
			})
		}

		toggleAlarm()
	}

	const handleMypageClick = () => {
		confirm.login({ targetUrl: `${window.location.origin}/mypage` })
	}

	const handleMenuToggle = () => {
		// sendGAEvent('event', 'button_click', {
		// 	event_category: 'category',
		// 	event_label: 'label',
		// 	value: 1,
		// })
		toggleMegaMenu()
	}

	useEffect(() => {
		setCurrentPath(encodeURIComponent(window.location.href))
	}, [pathname])

	useEffect(() => {
		if (activeSearch) {
			setTimeout(() => {
				handleSearchOpen()
			}, 200)
		}
	}, [activeSearch])

	return (
		<UtilBox>
			<GroupUtilBox
				justifyContent="flex-end"
				direction="row"
				display={{ xs: 'none', lg: 'flex' }}
			>
				<GroupUtilText component="button" onClick={toggleBrandMenu}>
					중앙그룹 브랜드
					<Icon16ArrowTiny direction="down" color={color.colGray5} />
				</GroupUtilText>
				<BrandMenu />
				{isMounted && <GroupUtilDivider darkMode={darkMode} />}
				{isMounted &&
					(isLoggedIn ? (
						<>
							<GroupUtilText component="button" onClick={handleLogout}>
								로그아웃
							</GroupUtilText>
							{/* <GroupUtilDivider darkMode={darkMode} />
							<GroupUtilLink href={process.env.NEXT_PUBLIC_AUTH_UPDATE_URL}>
								마이페이지
							</GroupUtilLink> */}
						</>
					) : (
						<>
							<GroupUtilLink
								data-ga-name="join"
								data-ga-key="join"
								data-ga-val="헤더영역_뉴스 메인"
								href={`${process.env.NEXT_PUBLIC_AUTH_JOIN_URL}?targetUrl=${currentPath}`}
								key={`join-${currentPath}`}
							>
								회원가입
							</GroupUtilLink>
							<GroupUtilDivider darkMode={darkMode} />
							<GroupUtilLink
								data-ga-name="login_page"
								data-ga-key="login"
								data-ga-val="헤더영역_뉴스 메인"
								href={`${process.env.NEXT_PUBLIC_AUTH_LOGIN_URL}?targetUrl=${currentPath}`}
								key={`login-${currentPath}`}
							>
								로그인
							</GroupUtilLink>
						</>
					))}
			</GroupUtilBox>
			<UIFlex direction="row" justify="flex-end">
				<UtilAnchor href="/report" darkMode={darkMode}>
					제보
				</UtilAnchor>
				<Box
					position="relative"
					display="flex"
					alignItems="center"
					marginLeft={{
						xs: pxToRem(12),
						md: pxToRem(16),
					}}
				>
					<UtilIconButton
						onClick={handleNotificationClick}
						aria-expanded={headerStates.alarmOpen}
					>
						{isLoggedIn && newNotice && newNotice.data ? (
							<Icon32DottedAlert color={darkMode ? color.colGray1 : ''} />
						) : (
							<Icon32Alert color={darkMode ? color.colGray1 : ''} />
						)}
						<UIText readonly>알림함 보기</UIText>
					</UtilIconButton>
					{headerStates.alarmOpen && (
						<NotificationPopover
							isApp={isApp}
							initialData={isLoggedIn ? data?.data ?? [] : []}
							ref={divRef}
						/>
					)}
				</Box>
				{/* 여기부터 */}
				<Box
					display={{ xs: 'none', md: 'flex' }}
					marginLeft={{
						xs: pxToRem(12),
						md: pxToRem(16),
					}}
				>
					{isLoggedIn ? (
						<UILink href="/mypage">
							<UtilIconButton component="span">
								<Icon32My color={darkMode ? color.colGray1 : ''} />
								<UIText readonly>마이페이지 </UIText>
							</UtilIconButton>
						</UILink>
					) : (
						<UtilIconButton onClick={handleMypageClick} sx={{ marginTop: pxToRem(-1) }}>
							<Icon32My color={darkMode ? color.colGray1 : ''} />
							<UIText readonly>마이페이지</UIText>
						</UtilIconButton>
					)}
				</Box>
				<UtilIconButton
					sx={{
						display: { xs: 'none', md: 'flex' },
						marginLeft: { xs: pxToRem(12), md: pxToRem(16) },
					}}
					onClick={toggleSearch}
					aria-expanded={headerStates.searchOpen}
				>
					<Icon32Search color={darkMode ? color.colGray1 : ''} />
					<UIText readonly>검색</UIText>
				</UtilIconButton>
				<Search />
				{/* 여기까지 모바일 감출 */}
				<UtilIconButton
					sx={{
						marginLeft: { xs: pxToRem(12), md: pxToRem(16) },
					}}
					onClick={handleMenuToggle}
					aria-expanded={headerStates.megaMenuOpen}
				>
					<Icon32Menu color={darkMode ? color.colGray1 : ''} />
					<UIText readonly>전체 메뉴</UIText>
				</UtilIconButton>
				<MegaMenu menu={menuTop} snsList={snsList} />
			</UIFlex>
		</UtilBox>
	)
}
export default NavUtils
