'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import zIndexes from '@ui/style/zIndexes.theme'
import { useMedia } from '@hooks/useMedia'
import { useEffect } from 'react'
import BrandMenuList from './BrandMenuList'
import { useCloseHeaderState, useHeaderStates } from '../store/store'

const BrandMenu = () => {
	const [headerStates] = useHeaderStates()
	const handleBrandClose = useCloseHeaderState('brandMenuOpen')

	// 디바이스 사이즈 md 이하로 내려가면 닫기
	const isMoDevice = useMedia('md')
	useEffect(() => {
		if (isMoDevice) {
			handleBrandClose()
		}
	}, [isMoDevice])

	return (
		headerStates.brandMenuOpen && (
			<Box
				position="absolute"
				top={pxToRem(12)}
				left="50%"
				width={{ lg: '928px', xl: '1216px' }}
				ml={{ lg: '-464px!important', xl: '-608px!important' }}
				borderRadius={pxToRem(8)}
				border={`1px solid ${color.colGray3}`}
				bgcolor={color.colWhite}
				boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.10)"
				zIndex={zIndexes.fullModal}
			>
				{/* 헤더 */}
				<UIFlex
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					padding={`${pxToRem(20)} ${pxToRem(32)}`}
					pr={pxToRem(24)}
				>
					<UIText fontSize={pxToRem(18)} lineHeight={pxToRem(24)} fontWeight={700}>
						중앙그룹 브랜드
					</UIText>
					<button type="button" onClick={handleBrandClose}>
						<Icon32Close />
						<UIText readonly>닫기</UIText>
					</button>
				</UIFlex>
				{/* 바디 */}
				<Box paddingX={pxToRem(32)} pb={pxToRem(32)}>
					<BrandMenuList />
				</Box>
			</Box>
		)
	)
}

export default BrandMenu
