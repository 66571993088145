const IconCustomerActivity = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#F6F6F6" />
			<rect
				x="0.25"
				y="0.25"
				width="39.5"
				height="39.5"
				rx="19.75"
				stroke="black"
				strokeOpacity="0.1"
				strokeWidth="0.5"
			/>
			<path
				d="M12 13V12.25H11.25V13H12ZM28 13H28.75V12.25H28V13ZM12 28H11.25V29.6307L12.4881 28.5694L12 28ZM16.6667 24V23.25H16.3892L16.1786 23.4306L16.6667 24ZM28 24V24.75H28.75V24H28ZM12 13.75H28V12.25H12V13.75ZM12.75 22V13H11.25V22H12.75ZM12.75 24V22H11.25V24H12.75ZM12.75 28V24H11.25V28H12.75ZM16.1786 23.4306L11.5119 27.4306L12.4881 28.5694L17.1548 24.5694L16.1786 23.4306ZM28 23.25H16.6667V24.75H28V23.25ZM27.25 13V24H28.75V13H27.25Z"
				fill="#777777"
			/>
			<line x1="16.5" y1="18.75" x2="23.5" y2="18.75" stroke="#777777" strokeWidth="1.5" />
		</svg>
	)
}

export default IconCustomerActivity
