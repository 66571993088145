import { styled } from '@mui/joy/styles'
import Box from '@mui/joy/Box'
import { SwiperSlide } from 'swiper/react'

export const IssueLayout = styled(Box)(({ theme }) => ({
	marginRight: theme.pxToRem(-20),
	marginLeft: theme.pxToRem(-20),
	paddingTop: theme.pxToRem(15),
	paddingBottom: theme.pxToRem(15),
	'& .swiper': {
		marginRight: 0,
		marginLeft: 0,
		paddingLeft: theme.pxToRem(20),
		paddingRight: theme.pxToRem(20),
	},
	[theme.breakpoints.up('md')]: {
		marginRight: 0,
		marginLeft: 0,
		paddingTop: 0,
		paddingBottom: theme.pxToRem(22),
		'& .swiper': {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	'.set-sticky &': {
		display: 'none',
	},
}))

export const SlideItem = styled(SwiperSlide)(({ theme }) => ({
	width: 'auto',
	'.swiper:not(.swiper-initialized) &:not(:last-child)': {
		marginRight: theme.pxToRem(24),
	},
}))
