import { theme } from '@ui/style'

const Icon32DottedAlert = ({ color, className }: SVGComponentProps) => {
	const iconColor = color || theme.color.colBlack

	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.8263 22.1621C14.561 22.4483 14.4001 22.829 14.4001 23.2494C14.4001 24.133 15.1164 24.8494 16.0001 24.8494C16.8838 24.8494 17.6001 24.133 17.6001 23.2494C17.6001 22.829 17.4392 22.4483 17.1739 22.1621L18.494 20.9385C19.0556 21.5443 19.4001 22.3576 19.4001 23.2494C19.4001 25.1272 17.8779 26.6494 16.0001 26.6494C14.1223 26.6494 12.6001 25.1272 12.6001 23.2494C12.6001 22.3576 12.9446 21.5443 13.5062 20.9385L14.8263 22.1621Z"
				fill={iconColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.8991 12.6272C23.8997 12.6681 23.9001 12.7091 23.9001 12.7501V18.3551L25.6066 22.4001H6.39355L8.10005 18.3551V12.7501C8.10005 8.66319 11.4131 5.3501 15.5001 5.3501H16.5001C16.9087 5.3501 17.3097 5.38323 17.7003 5.44694C17.389 5.97004 17.1704 6.55478 17.0672 7.17847C16.8807 7.15971 16.6915 7.1501 16.5001 7.1501H15.5001C12.4073 7.1501 9.90005 9.6573 9.90005 12.7501V18.7192L9.10655 20.6001H22.8936L22.1001 18.7192V12.9995C22.7359 12.987 23.3426 12.8559 23.8991 12.6272Z"
				fill={iconColor}
			/>
			<path
				d="M25 8C25 9.65686 23.6569 11 22 11C20.3431 11 19 9.65686 19 8C19 6.34315 20.3431 5 22 5C23.6569 5 25 6.34315 25 8Z"
				fill="#EE0007"
			/>
		</svg>
	)
}

export default Icon32DottedAlert
