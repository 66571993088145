import { theme } from '@ui/style'

export const Icon16Close = ({ color, className }: SVGComponentProps) => {
	const iconColor = color || theme.color.colBlack
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			className={className}
		>
			<path d="M1 1L13 13" stroke={iconColor} strokeWidth="1.8" />
			<path d="M1 13L13 1" stroke={iconColor} strokeWidth="1.8" />
		</svg>
	)
}
