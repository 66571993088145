import { ApiResultVoListMenuResponseVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { queryPrefetch } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type MenuPcResponse = CommonResponse<ApiResultVoListMenuResponseVo>

const path = '/restapi/v1/get/system/menu/pc'
const queryKeyString = () => ['@system/menu/pc'] as const

const getQuery = async (): Promise<ApiResultVoListMenuResponseVo> => {
	return fetchAPI({ path })
}

export const queryMenuPc = () => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(),
})

export const useMenuPc = () => {
	const { data, ...rest } = useQuery(queryMenuPc())
	return { data: (data as ApiResultVoListMenuResponseVo)?.data, ...rest }
}
export const useMenuPcQuery = (
	input?: CommonUseQueryInput<ApiResultVoListMenuResponseVo, unknown>,
) => {
	const { data, ...rest } = useQuery({
		queryKey: [...queryKeyString()],
		queryFn: () => getQuery(),
		...input?.opt,
	})
	return { data, ...rest }
}
export const prefetchMenuPc = () => queryPrefetch(queryMenuPc())
