'use client'

import { Box } from '@mui/joy'

import { NoticeResponseVo } from '@schemas/auth'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import NotificationItem from './NotificationItem'

type Props = {
	data: NoticeResponseVo[]
	isLoading: boolean
}

const NotificationList = ({ data, isLoading = false }: Props) => {
	const hasNoticeList = data && data.length > 0

	return (
		<UIFlex flexGrow={1}>
			{isLoading ? null : (
				<>
					{hasNoticeList ? (
						data.map((notice) => (
							<NotificationItem key={notice.noticeIdx} data={notice} />
						))
					) : (
						<UIFlex
							flexGrow={1}
							flexShrink={0}
							alignContent="center"
							justifyContent="center"
							borderBottom={`1px solid ${color.colGray2}`}
						>
							<UIText
								fontSize={pxToRem(15)}
								lineHeight={pxToRem(22)}
								fontWeight={500}
								color={color.colGray5}
								textAlign="center"
							>
								알림 내역이 없습니다.
							</UIText>
						</UIFlex>
					)}
					<Box pt={pxToRem(16)}>
						<UIText
							fontSize={pxToRem(13)}
							lineHeight={pxToRem(18)}
							textAlign="center"
							color={color.colGray4}
						>
							최근 7일까지의 알림 내역을 제공합니다.
						</UIText>
					</Box>
				</>
			)}
		</UIFlex>
	)
}

export default NotificationList
