'use client'

import MostReadArticlesSection from './MostReadArticlesSection'
import SearchInput from './SearchInput'
import SearchLayout from './SearchLayout'
import SearchPopularKeywords from './SearchPopularKeywords'

const Search = () => {
	return (
		<SearchLayout articleList={<MostReadArticlesSection />}>
			<SearchInput hasDefaultKeyword={false} />
			<SearchPopularKeywords />
		</SearchLayout>
	)
}

export default Search
