import React from 'react'

const IconWatchReservation = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#F6F6F6" />
			<rect
				x="0.25"
				y="0.25"
				width="39.5"
				height="39.5"
				rx="19.75"
				stroke="black"
				strokeOpacity="0.1"
				strokeWidth="0.5"
			/>
			<circle cx="20" cy="20" r="8" stroke="#777777" strokeWidth="1.5" />
			<path d="M20 16V20H23" stroke="#777777" strokeWidth="1.5" strokeLinecap="square" />
		</svg>
	)
}

export default IconWatchReservation
