import { Box } from '@mui/joy'
import React, { forwardRef, useEffect } from 'react'

import { NoticeType } from '@services/api/Notification/Notice/List/types'
import { useCloseHeaderState, useHeaderStates, useOpenHeaderState } from '../store/store'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'

import { Icon16Close } from 'src/assets/icons/Icon16Close'
import Icon24Delete from 'src/assets/icons/Icon24Delete'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import Icon32Delete from 'src/assets/icons/Icon32Delete'
import Icon32Settings from 'src/assets/icons/Icon32Settings'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'

type Props = {
	isApp: boolean
	alarmTab: React.ReactNode
	deleteButtonProps?: React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
	setNoticeType: React.Dispatch<React.SetStateAction<NoticeType | null>>
	children: React.ReactNode
}

const NotificationWrapper = (
	{ isApp = false, alarmTab, deleteButtonProps, setNoticeType, children }: Props,
	ref: React.Ref<HTMLDivElement>,
) => {
	const [headerStates] = useHeaderStates()

	const handleAlarmClose = useCloseHeaderState('alarmOpen')
	const handleSettingOpen = useOpenHeaderState('settingOpen')

	useEffect(() => {
		if (headerStates.alarmOpen) {
			document.body.classList.add('scroll-lock-under-md')
		} else {
			document.body.classList.remove('scroll-lock-under-md')
			document.body.classList.remove('scroll-lock-all')
		}
		return () => {
			setNoticeType(null)
			document.body.classList.remove('scroll-lock-under-md')
			document.body.classList.remove('scroll-lock-all')
		}
	}, [headerStates.alarmOpen, setNoticeType])

	return (
		headerStates.alarmOpen && (
			<UIFlex
				width={{ xs: '100%', md: pxToRem(420) }}
				height={{ xs: '100%', md: 'auto' }}
				maxHeight={{ md: 'calc(100vh - 100px)' }}
				position={{ xs: 'fixed', md: 'absolute' }}
				right={{ xs: 0, md: '-144px' }}
				top={{ xs: 0, md: 'calc(100% + 8px)' }}
				bgcolor={color.colWhite}
				borderRadius={{ md: pxToRem(8) }}
				boxShadow="0px 4px 16px 0px rgba(36, 36, 36, 0.15)"
				zIndex={zIndexes.fullModal + 1}
				ref={ref}
			>
				<UIFlex
					padding={{
						xs: `${pxToRem(9)} ${pxToRem(12)} 0 ${pxToRem(20)}`,
						md: `${pxToRem(20)} ${pxToRem(24)} 0`,
					}}
				>
					<UIFlex flexDirection="row" justifyContent="space-between">
						<UIText
							fontSize={pxToRem(20)}
							lineHeight={{ xs: pxToRem(32), md: pxToRem(24) }}
							fontWeight={700}
						>
							알림
						</UIText>
						<UIFlex
							flexDirection="row"
							alignItems="center"
							sx={{
								'.icon-pc': {
									display: { xs: 'none', md: 'flex' },
								},
								'.icon-mo': {
									display: { xs: 'flex', md: 'none' },
								},
								button: {
									'&:disabled': {
										cursor: 'not-allowed',
									},
								},
								'&> *:not(:first-child)': { marginLeft: pxToRem(12) },
							}}
						>
							<button type="button" {...deleteButtonProps}>
								<UIFlex flexDirection="row" alignItems="center">
									<Icon24Delete className="icon-pc" />
									<Icon32Delete className="icon-mo" />
									<UIText
										fontSize={pxToRem(12)}
										fontWeight={600}
										lineHeight={pxToRem(20)}
										ml={pxToRem(2)}
									>
										전체삭제
									</UIText>
								</UIFlex>
							</button>
							<Box width="1px" height={pxToRem(20)} bgcolor={color.colGray3} />
							{/* 설정 버튼 App에서만 노출 */}
							{isApp && (
								<button type="button" onClick={handleSettingOpen}>
									<Icon32Settings />
									<UIText readonly>설정</UIText>
								</button>
							)}
							<button type="button" onClick={handleAlarmClose}>
								<UIFlex
									width={pxToRem(24)}
									height={pxToRem(24)}
									alignItems="center"
									justifyContent="center"
									className="icon-pc"
								>
									<Icon16Close />
								</UIFlex>
								<Icon32Close className="icon-mo" />
								<UIText readonly>닫기</UIText>
							</button>
						</UIFlex>
					</UIFlex>
					<Box mt={pxToRem(8)}>{alarmTab}</Box>
				</UIFlex>
				<UIFlex
					height={{ md: pxToRem(442) }}
					overflow="auto"
					flexGrow={1}
					px={{ xs: pxToRem(20), md: pxToRem(24) }}
					pb={{ xs: pxToRem(20), md: pxToRem(24) }}
				>
					{children}
				</UIFlex>
			</UIFlex>
		)
	)
}

export default forwardRef(NotificationWrapper)
