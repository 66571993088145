import { forwardRef, useCallback, useEffect, useState } from 'react'

import { NoticeResponseVo } from '@schemas/auth'
import { useNotificationNoticeListQuery } from '@services/api/Notification/Notice/List'
import { NoticeType } from '@services/api/Notification/Notice/List/types'
import { useNotificationNoticeRecentMutation } from '@services/api/Notification/Notice/Recent'

import useModal from '@components/layout/Modal'
import NotificationList from './NotificationList'
import NotificationTab from './NotificationTab'
import NotificationWrapper from './NotificationWrapper'

type AlarmProps = {
	isApp: boolean
	initialData: NoticeResponseVo[]
}

const NotificationPopover = forwardRef<HTMLDivElement, AlarmProps>(
	({ isApp = false, initialData }, ref) => {
		const [noticeType, setNoticeType] = useState<NoticeType | null>(null)
		const confirm = useModal()

		// API: 알림목록 조회
		const { data, isLoading, refetch } = useNotificationNoticeListQuery({
			arg: { noticeType: noticeType ?? undefined },
			opt: { enabled: !!noticeType },
		})
		// API: 최근알림 삭제
		const { mutateAsync: mutateRecentDelete } = useNotificationNoticeRecentMutation()

		const handleDeleteAllNoticeList = useCallback(() => {
			confirm.open({
				message: '알림 메시지를 전체 삭제 하시겠습니까?',
				buttons: [
					{ text: '취소' },
					{
						text: '확인',
						onClick: async (close) => {
							await mutateRecentDelete().then(() => {
								refetch()
							})
							close()
						},
					},
				],
			})
		}, [confirm, mutateRecentDelete, refetch])

		return (
			<NotificationWrapper
				isApp={isApp}
				deleteButtonProps={{
					onClick: handleDeleteAllNoticeList,
					disabled: !(data && data?.data?.length > 0),
				}}
				alarmTab={<NotificationTab noticeType={noticeType} setNoticeType={setNoticeType} />}
				setNoticeType={setNoticeType}
				ref={ref}
			>
				<NotificationList
					data={noticeType ? data?.data ?? [] : initialData ?? []}
					isLoading={isLoading}
				/>
			</NotificationWrapper>
		)
	},
)

export default NotificationPopover
