import Box from '@mui/joy/Box'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import { styled } from '@mui/joy/styles'
import { pxToRem } from '@ui/style/muiTheme'

export const NavLayout = styled(UIFlex)(({ theme }) => ({
	zIndex: 10,
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	[theme.breakpoints.up('lg')]: {
		alignItems: 'flex-end',
		paddingBottom: pxToRem(22),
		'.set-sticky &': {
			paddingBottom: 0,
			alignItems: 'center',
		},
	},
}))

export const JTBCLogoArea = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-end',
	gap: 6.5,

	[theme.breakpoints.down('md')]: {
		'.set-sticky &': {
			paddingBottom: pxToRem(3),
		},
	},

	[theme.breakpoints.up('md')]: {
		gap: 8.5,
		padding: `${pxToRem(5)} ${pxToRem(2.156)} ${pxToRem(9)} 0`,
	},

	[theme.breakpoints.up('xl')]: {
		gap: 9,
		padding: `0 0 ${pxToRem(8)} 0`,

		'.set-sticky &': {
			gap: 8.5,
			padding: `${pxToRem(5)} ${pxToRem(2.156)} ${pxToRem(9)} 0`,
		},
	},
}))

interface JTBCLogoProps {
	darkMode?: boolean
}
export const JTBCLogo = styled(UILink, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<JTBCLogoProps>(({ theme, darkMode }) => ({
	display: 'block',
	width: theme.pxToRem(55.3),
	height: theme.pxToRem(29),
	backgroundSize: `${theme.pxToRem(55.3)} ${theme.pxToRem(29)}`,
	backgroundImage: darkMode
		? 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/mo_jtbc_white_logo_2x.png)'
		: 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/mo_jtbc_blue_logo_2x.png)',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'left center',

	[theme.breakpoints.up('md')]: {
		width: theme.pxToRem(66.7),
		height: theme.pxToRem(34),
		backgroundSize: `${theme.pxToRem(66.7)} ${theme.pxToRem(34)}`,
		backgroundImage: darkMode
			? 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/pc_jtbc_white_logo_2x.png)'
			: 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/pc_jtbc_blue_logo_2x.png)',
	},

	[theme.breakpoints.up('xl')]: {
		width: theme.pxToRem(78.5),
		height: theme.pxToRem(40),
		backgroundSize: `${theme.pxToRem(78.5)} ${theme.pxToRem(40)}`,

		'.set-sticky &': {
			width: theme.pxToRem(66.7),
			height: theme.pxToRem(34),
			backgroundSize: `${theme.pxToRem(66.7)} ${theme.pxToRem(34)}`,
		},
	},
}))

export const NEWSLogo = styled(UILink, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<JTBCLogoProps>(({ theme, darkMode }) => ({
	display: 'block',
	width: theme.pxToRem(56),
	height: theme.pxToRem(12.8),
	backgroundSize: `${theme.pxToRem(56)} ${theme.pxToRem(12.8)}`,
	backgroundImage: darkMode
		? 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/mo_news_white_logo_2x.png)'
		: 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/mo_news_blue_logo_2x.png)',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'left center',

	[theme.breakpoints.up('md')]: {
		width: theme.pxToRem(67),
		height: theme.pxToRem(16.2),
		backgroundSize: `${theme.pxToRem(67)} ${theme.pxToRem(16.2)}`,
		backgroundImage: darkMode
			? 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/pc_news_white_logo_2x.png)'
			: 'url(https://nstatic.jtbc.co.kr/news-front/images/logo/pc_news_blue_logo_2x.png)',
	},

	[theme.breakpoints.up('xl')]: {
		width: theme.pxToRem(79),
		height: theme.pxToRem(18.9),
		backgroundSize: `${theme.pxToRem(79)} ${theme.pxToRem(18.9)}`,

		'.set-sticky &': {
			width: theme.pxToRem(67),
			height: theme.pxToRem(16.2),
			backgroundSize: `${theme.pxToRem(67)} ${theme.pxToRem(16.2)}`,
		},
	},
}))
