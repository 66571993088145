'use client'

import { Avatar, Box, styled } from '@mui/joy'

import { NoticeResponseVo } from '@schemas/auth'
import { getRelativeTime } from '@utils/date'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import IconCustomerActivity from 'src/assets/icons/IconCustomerActivity'
import IconNewsflash from 'src/assets/icons/IconNewsflash'
import IconNotificationFallbackImage from 'src/assets/icons/IconNotificationFallbackImage'
import IconWatchReservation from 'src/assets/icons/IconWatchReservation'

import UIFlex from '@components/ui/UIFlex'
import UIImage from '@components/ui/UIImage'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'

interface NotificationItemProps {
	data: NoticeResponseVo
}

const IconBox = styled(Box)`
	width: ${pxToRem(40)};
	height: ${pxToRem(40)};
	border-radius: ${pxToRem(40)};
	flex-shrink: 0;
	overflow: hidden;
	background-color: ${color.colGray1};
	display: flex;
	align-items: center;
	justify-content: center;

	.MuiAvatar-root {
		border: 0.5px solid rgba(0, 0, 0, 0.1);
	}
`

const NotificationItem = ({ data }: NotificationItemProps) => {
	const {
		noticeImgUrl,
		noticeDate,
		noticeTitle,
		noticeContent,
		isNewsflash,
		noticeCode,
		linkUrlInfo,
	} = data
	const domain = process.env.NEXT_PUBLIC_SITE_URL
	const target =
		linkUrlInfo.linkCode === 'URL' && !linkUrlInfo.linkValue?.includes(domain)
			? 'blank'
			: linkUrlInfo.linkTarget

	const renderIcon = () => {
		switch (noticeCode.code) {
			case 'ACTION':
				return <IconCustomerActivity />
			case 'LIVE_RESERVE':
				return <IconWatchReservation />
			default:
				if (linkUrlInfo.linkValue?.includes('journalist')) {
					return <Avatar variant="outlined" />
				}
				return <IconNotificationFallbackImage />
		}
	}

	return (
		<UIFlex
			flexDirection="row"
			flexShrink={0}
			alignItems="flex-start"
			pt={pxToRem(14)}
			pb={pxToRem(11)}
			borderBottom={`1px solid ${color.colGray2}`}
			sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(12) } }}
		>
			{isNewsflash ? (
				<IconBox>
					<IconNewsflash />
				</IconBox>
			) : !noticeImgUrl ? (
				<IconBox
					border={noticeCode.code === 'NEWS' ? '0.5px solid rgba(0, 0, 0, 0.1)' : 'none'}
				>
					{renderIcon()}
				</IconBox>
			) : (
				<Box
					width={pxToRem(40)}
					flexShrink={0}
					overflow="hidden"
					borderRadius="9999px"
					border="0.5px solid rgba(0,0,0,0.1)"
				>
					<UIImage ratio={1} src={noticeImgUrl} alt="" />
				</Box>
			)}
			<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(4) } }}>
				<UIFlex
					flexDirection="row"
					alignItems="center"
					sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(8) } }}
				>
					<UIText
						color={isNewsflash ? color.colLiveRed : color.colPointBlue}
						fontSize={pxToRem(12)}
						lineHeight={pxToRem(16)}
						fontWeight={700}
					>
						{noticeTitle}
					</UIText>
					<UIText
						color={color.colGray5}
						fontSize={pxToRem(12)}
						lineHeight={pxToRem(16)}
						fontWeight={500}
					>
						{getRelativeTime(noticeDate)}
					</UIText>
				</UIFlex>
				<UILink link={{ ...linkUrlInfo, linkTarget: target }}>
					<UIText
						lineClamp={2}
						fontSize={pxToRem(15)}
						lineHeight={pxToRem(22)}
						fontWeight={500}
					>
						{noticeContent}
					</UIText>
				</UILink>
			</UIFlex>
		</UIFlex>
	)
}

export default NotificationItem
