'use client'

import UILink from '@components/ui/UILink'
import { Grid } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import color from '@ui/style/color.theme'
import UIText from '@components/ui/UIText'
import { HierarchyMenuResponseVo } from '@schemas/non-auth'
import { Fragment } from 'react'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useCloseHeaderState } from '../../store/store'

interface EtcMenuItemProps {
	menu: Array<HierarchyMenuResponseVo>
}
const EtcMenuItem = ({ menu }: EtcMenuItemProps) => {
	const { darkMode } = useDarkModeControl()
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')
	return (
		<Grid
			container
			fontSize={0}
			columnSpacing={{ xs: pxToRem(16), md: 0 }}
			sx={{
				'&>*:not(:first-child)': {
					marginTop: {
						md: pxToRem(14),
						lg: pxToRem(16),
					},
				},
			}}
			className="etcMenu"
		>
			{menu.map((item, index) => (
				<Fragment key={item.menuIdx}>
					<Grid xs={6} md={12}>
						<UILink link={item.linkUrlInfo} onClick={handleMegaMenuClose}>
							<UIText
								fontSize={{ xs: pxToRem(15), md: pxToRem(13), lg: pxToRem(14) }}
								lineHeight={{ xs: pxToRem(18), md: pxToRem(20) }}
								fontWeight={{ xs: 700, md: 600 }}
								color={darkMode ? color.colGray1 : color.colBlack}
							>
								{item.menuName}
							</UIText>
						</UILink>
					</Grid>
					{index % 2 === 1 && menu.length - 1 !== index && (
						<UIDivider
							orientation="horizontal"
							sx={{
								display: { xs: 'block', md: 'none' },
								marginY: pxToRem(20),
								background: darkMode ? '#303030' : color.colGray2,
							}}
						/>
					)}
				</Fragment>
			))}
		</Grid>
	)
}

export default EtcMenuItem
