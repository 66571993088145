'use client'

import { useGetCookieHeader } from '@store/cookieHeader'
import { useMutation } from '@tanstack/react-query'
import { DeviceTokenReqModel, DeviceTokenResponse, deviceTokenMutationProps } from '.'

export const useDeviceTokenMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<DeviceTokenResponse, Error, DeviceTokenReqModel>(
		deviceTokenMutationProps(cookieHeader),
	)
	return { data, ...rest }
}
