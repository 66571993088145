import { theme } from '@ui/style/theme'
import { useMediaQuery } from './useMediaQuery'

export const useMedia = (size?: string): boolean | string => {
	const index = theme.breakpoints.keys.findIndex((key) => key === size)
	const isXLarge = useMediaQuery(`only screen and (min-width: ${theme.breakpoint.xl}px)`)
	const isLarge = useMediaQuery(`only screen and (min-width: ${theme.breakpoint.lg}px)`)
	const isMedium = useMediaQuery(`only screen and (min-width: ${theme.breakpoint.md}px)`)
	const isSmall = useMediaQuery(`only screen and (min-width: ${theme.breakpoint.sm}px)`)
	let currentMedia = 0
	if (isSmall) {
		currentMedia = 1
	}
	if (isMedium) {
		currentMedia = 2
	}
	if (isLarge) {
		currentMedia = 3
	}
	if (isXLarge) {
		currentMedia = 4
	}
	return index >= 0 ? currentMedia <= index : theme.breakpoints.keys[currentMedia]
}
