type Props = {
	color?: string
}
const LogoIos = ({ color = '#000' }: Props) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Frame 427319471">
				<g id="apple Icons">
					<g id="&#60;Group&#62;">
						<path
							id="&#60;Path&#62;"
							d="M17.6413 13.4659C17.6481 12.9368 17.7886 12.4181 18.0498 11.9579C18.311 11.4977 18.6843 11.1111 19.135 10.834C18.8487 10.4251 18.4709 10.0885 18.0318 9.8511C17.5926 9.61368 17.1041 9.48194 16.6052 9.46632C15.5407 9.3546 14.5088 10.1033 13.9664 10.1033C13.4135 10.1033 12.5784 9.47742 11.6791 9.49592C11.0974 9.51471 10.5305 9.68386 10.0336 9.98689C9.53677 10.2899 9.12686 10.7165 8.84386 11.225C7.61796 13.3475 8.53237 16.4668 9.70668 18.1824C10.2942 19.0225 10.9809 19.9609 11.8793 19.9277C12.7586 19.8912 13.0869 19.367 14.1482 19.367C15.1997 19.367 15.5077 19.9277 16.4244 19.9065C17.3679 19.8912 17.9623 19.0627 18.5292 18.2146C18.9514 17.616 19.2762 16.9544 19.4917 16.2544C18.9436 16.0225 18.4758 15.6345 18.1468 15.1386C17.8177 14.6428 17.6419 14.061 17.6413 13.4659Z"
							fill={color}
						/>
						<path
							id="&#60;Path&#62;_2"
							d="M15.9095 8.33759C16.4239 7.72007 16.6773 6.92636 16.6159 6.125C15.83 6.20754 15.1041 6.58315 14.5827 7.17697C14.3278 7.46707 14.1326 7.80457 14.0082 8.17017C13.8838 8.53576 13.8327 8.92229 13.8578 9.30765C14.2509 9.3117 14.6397 9.2265 14.9951 9.05847C15.3505 8.89044 15.6631 8.64396 15.9095 8.33759Z"
							fill={color}
						/>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default LogoIos
