'use client'

import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
	ArticleRankViewQueryKey,
	ArticleRankViewReqModel,
	ArticleRankViewResponse,
	articleRankViewQueryKey,
} from './types'
import { fetchArticleRankView } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useArticleRankViewQuery = (
	args?: ArticleRankViewReqModel,
	opt?: Omit<
		UseQueryOptions<
			ArticleRankViewResponse,
			Error,
			ArticleRankViewResponse,
			ArticleRankViewQueryKey
		>,
		'queryKey' | 'queryFn'
	>,
) => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useQuery<
		ArticleRankViewResponse,
		Error,
		ArticleRankViewResponse,
		ArticleRankViewQueryKey
	>({
		queryKey: [...articleRankViewQueryKey, args ?? {}],
		queryFn: () => fetchArticleRankView(args, cookieHeader),
		...opt,
	})
	return { data: data?.data, ...rest }
}
