import { theme } from '@ui/style'

const Icon32MegaLive = ({ color }: SVGComponentProps) => {
	const colorValue = color || theme.color.colBlack
	return (
		<svg
			width="33"
			height="32"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Icons / 16 / Play">
				<g id="Group 48096290">
					<path
						id="Subtract"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M6.67084 6.1712C4.15542 8.68661 2.59961 12.1616 2.59961 16C2.59961 19.8384 4.15543 23.3134 6.67084 25.8288L7.94363 24.556C5.75395 22.3663 4.39961 19.3413 4.39961 16C4.39961 12.6587 5.75395 9.63367 7.94363 7.444L6.67084 6.1712ZM25.0556 7.44402L26.3284 6.17123C28.8438 8.68663 30.3996 12.1616 30.3996 16C30.3996 19.8384 28.8438 23.3134 26.3284 25.8288L25.0556 24.556C27.2453 22.3663 28.5996 19.3413 28.5996 16C28.5996 12.6587 27.2453 9.63369 25.0556 7.44402ZM10.2064 9.70674C8.59578 11.3173 7.59961 13.5423 7.59961 16C7.59961 18.4577 8.59578 20.6827 10.2064 22.2933L11.4792 21.0205C10.1943 19.7356 9.39961 17.9606 9.39961 16C9.39961 14.0394 10.1943 12.2644 11.4792 10.9795L10.2064 9.70674ZM21.5201 10.9796L22.7929 9.70676C24.4034 11.3173 25.3996 13.5423 25.3996 16C25.3996 18.4577 24.4034 20.6827 22.7929 22.2932L21.5201 21.0204C22.8049 19.7356 23.5996 17.9606 23.5996 16C23.5996 14.0394 22.8049 12.2644 21.5201 10.9796Z"
						fill={colorValue}
					/>
					<circle
						id="Ellipse 2344"
						cx="16.5"
						cy="16"
						r="3"
						stroke={colorValue}
						strokeWidth="1.8"
					/>
				</g>
			</g>
		</svg>
	)
}

export default Icon32MegaLive
