import { ApiResultVoListNewsHomeTopKeywordResponseVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type TopKeywordResponse = CommonResponse<ApiResultVoListNewsHomeTopKeywordResponseVo>
export type TopKeywordResponseData = ApiResultVoListNewsHomeTopKeywordResponseVo['data']

const path = '/restapi/v1/get/template/news-home/top-keyword/list'
const queryKeyString = ['@template/news-home/top-keyword/list'] as const

export const getQuery = async (
	cookieHeader?: string,
): Promise<ApiResultVoListNewsHomeTopKeywordResponseVo> => {
	return fetchAPI({ path, cookieHeader })
}

export const queryTopKeyword = () => ({
	queryKey: queryKeyString,
	queryFn: () => getQuery(),
})
export const useTopKeyword = () => {
	const { data, ...rest } = useQuery(queryTopKeyword())
	return { data: (data as ApiResultVoListNewsHomeTopKeywordResponseVo)?.data, ...rest }
}
export const useTopKeywordQuery = (
	input?: CommonUseQueryInput<ApiResultVoListNewsHomeTopKeywordResponseVo, unknown>,
) => {
	const { data, ...rest } = useQuery({
		...queryTopKeyword(),
		...input?.opt,
	})
	return { data, ...rest }
}

export const prefetchTopKeyword = (cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString, () => getQuery(cookieHeader))
