'use client'

import Link from 'next/link'

import { useDarkModeControl } from '@hooks/useDarkModeControl'
import useSearchHistory from '@hooks/useSearchHistory'
import { useJtbcSearchPopularKeywordQuery } from '@services/api/External/JtbcSearch/PopularKeyword'

import { pxToRem } from '@ui/style/muiTheme'
import { useHeaderStates } from '../store/store'

import UIChip from '@components/ui/UIChip'
import UIFlex from '@components/ui/UIFlex'
import { uuidv4 } from '@utils/random'
import { Box } from '@mui/joy'

const SearchPopularKeywords = () => {
	const { darkMode } = useDarkModeControl()
	const [headerStates, setHeaderStates] = useHeaderStates()
	const { saveHistory, closeHistory } = useSearchHistory()

	const { data: keywords } = useJtbcSearchPopularKeywordQuery({
		opt: {
			enabled: headerStates.searchOpen,
		},
	})

	return (
		<Box>
			<UIFlex
				flexDirection="row"
				flexWrap="wrap"
				mt={pxToRem(-8)}
				ml={pxToRem(-8)}
				sx={{ '&> *': { marginLeft: pxToRem(8), marginTop: pxToRem(8) } }}
			>
				{keywords?.data?.map((kwd) => (
					<UIChip
						key={`${kwd.popularKeyword}-${uuidv4()}`}
						color={darkMode ? 'darkMode' : 'neutral'}
						slotProps={{
							action: {
								component: Link,
								href: `/search?keyword=${
									kwd.popularKeyword && encodeURIComponent(kwd.popularKeyword)
								}`,
							},
						}}
						onClick={() => {
							saveHistory(kwd.popularKeyword ?? '')

							closeHistory()
							setHeaderStates((prev) => ({
								...prev,
								searchOpen: false,
								megaMenuOpen: false,
							}))
						}}
					>
						{kwd.popularKeyword}
					</UIChip>
				))}
			</UIFlex>
		</Box>
	)
}

export default SearchPopularKeywords
