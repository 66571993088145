'use client'

import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

interface SubItem {
	title: string
	href: string
}
interface BrandMenuItemProps extends Omit<UIFlexProps, 'ref'> {
	title: string
	items: SubItem[]
}
const BrandMenuItem = ({ title, items, ...props }: BrandMenuItemProps) => {
	return (
		<UIFlex
			fontSize={0}
			{...props}
			sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(12) } }}
		>
			<UIText
				fontSize={{ lg: pxToRem(14), xl: pxToRem(15) }}
				lineHeight={pxToRem(22)}
				fontWeight={700}
			>
				{title}
			</UIText>
			<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}>
				{items.map((item) => (
					<UILink key={item.title} href={item.href} target="_blank">
						<UIText
							fontSize={{ lg: pxToRem(13), xl: pxToRem(14) }}
							lineHeight={pxToRem(20)}
							fontWeight={500}
							color={color.colGray5}
						>
							{item.title}
						</UIText>
					</UILink>
				))}
			</UIFlex>
		</UIFlex>
	)
}

export default BrandMenuItem
