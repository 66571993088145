'use client'

import { forwardRef } from 'react'
import { Box, BoxProps, styled } from '@mui/joy'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useHeaderStates } from './store/store'

interface HeaderGroupRootProps extends BoxProps {
	darkMode?: boolean
}
export const HeaderGroupRoot = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<HeaderGroupRootProps>(({ theme, darkMode }) => ({
	position: 'relative',
	zIndex: 1,
	backgroundColor: darkMode ? theme.color.colBlack : theme.color.colWhite,
	'&:after': {
		content: '""',
		display: 'block',
		position: 'absolute',
		bottom: '-1px',
		left: 0,
		width: '100%',
		height: '1px',
		backgroundColor: darkMode ? '#303030' : theme.color.colGray3,
	},
	'&.megaMenu-opened': {
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: 0,
			left: 0,
			width: '100%',
			height: '1px',
			backgroundColor: darkMode ? '#303030' : theme.color.colGray3,
		},
	},
}))

interface HeaderGroupProps {
	children: React.ReactNode
}

const HeaderGroup = forwardRef<HTMLElement | undefined, HeaderGroupProps>(({ children }, ref) => {
	const [headerStates] = useHeaderStates()
	const { darkMode } = useDarkModeControl()

	return (
		<HeaderGroupRoot
			ref={ref}
			component="hgroup"
			className={`header-group ${headerStates.megaMenuOpen ? 'megaMenu-opened' : ''}`}
			darkMode={darkMode}
		>
			{children}
		</HeaderGroupRoot>
	)
})

export default HeaderGroup
