import { ApiResultVoListSnsResponseVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type SNSListResponse = CommonResponse<ApiResultVoListSnsResponseVo>

const path = '/restapi/v1/get/system/sns/list'
const queryKeyString = () => ['@system/sns/list'] as const

const getQuery = async (): Promise<ApiResultVoListSnsResponseVo> => {
	return fetchAPI({ path })
}

export const querySNSList = () => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(),
})

export const useSNSList = () => {
	const { data, ...rest } = useQuery(querySNSList())
	return { data: (data as ApiResultVoListSnsResponseVo)?.data, ...rest }
}
export const useSNSListQuery = (
	input?: CommonUseQueryInput<ApiResultVoListSnsResponseVo, unknown>,
) => {
	const { data, ...rest } = useQuery({
		...querySNSList(),
		...input?.opt,
	})
	return { data, ...rest }
}

export const prefetchSNSList = () => createPrefetchQuery(queryKeyString(), () => getQuery())
