'use client'

import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'
import { useRef, useState } from 'react'

interface TimePickerProps {
	time?: string
	onClick: () => void
}
const TimePicker = ({ time, onClick }: TimePickerProps) => {
	const inputRef = useRef<HTMLInputElement>(null)

	const formatTime = (selectedTime: string) => {
		const [hours, minutes] = selectedTime.split(':')
		const ampm = parseInt(hours, 10) >= 12 ? '오후' : '오전'
		let formattedHours: number | string = Number(hours) % 12 || 12
		formattedHours = formattedHours < 10 ? `0${formattedHours}` : formattedHours
		const formattedMinutes = minutes
		return `${ampm} ${formattedHours}:${formattedMinutes}`
	}

	return (
		<Box component="button" onClick={onClick}>
			<UIText
				fontSize={pxToRem(14)}
				lineHeight={pxToRem(22)}
				fontWeight={500}
				color={color.colGray4}
			>
				{time && formatTime(time)}
			</UIText>
		</Box>
	)
}

export default TimePicker
