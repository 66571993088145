import { ApiResultVoHierarchyMenuResponseWrapperVo } from '@schemas/non-auth'
import { useQuery } from '@tanstack/react-query'
import { queryPrefetch } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'

export type MenuTopResponse = CommonResponse<ApiResultVoHierarchyMenuResponseWrapperVo>

const path = '/restapi/v1/get/system/menu/top'
const queryKeyString = () => ['@system/menu/top'] as const

const getQuery = async (): Promise<ApiResultVoHierarchyMenuResponseWrapperVo> => {
	return fetchAPI({ path })
}

export const queryMenuTop = () => ({
	queryKey: [...queryKeyString()],
	queryFn: () => getQuery(),
})

export const useMenuTop = () => {
	const { data, ...rest } = useQuery(queryMenuTop())
	return { data: data?.data, ...rest }
}

export const useMenuTopQuery = (
	input?: CommonUseQueryInput<ApiResultVoHierarchyMenuResponseWrapperVo, unknown>,
) => {
	const { data, ...rest } = useQuery({
		...input?.opt,
		...queryMenuTop(),
	})
	return { data, ...rest }
}

export const prefetchMenuTop = () => queryPrefetch(queryMenuTop())
