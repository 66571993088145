'use client'

import UIDivider from '@components/ui/UIDivider'
import { Grid } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import BrandMenuItem from './BrandMenuItem'

const newsPaperItems = [
	{ title: '중앙일보', href: 'https://www.joongang.co.kr/' },
	{ title: '중앙 SUNDAY', href: 'https://www.joongang.co.kr/sunday' },
	{ title: '일간스포츠', href: 'http://isplus.joins.com/' },
	{ title: 'Korea Joongang Daily', href: 'http://koreajoongangdaily.joins.com/' },
	{ title: 'The Korea Daily', href: 'http://www.koreadaily.com/' },
]
const broadcastItems = [
	{ title: 'JTBC', href: 'https://jtbc.co.kr/' },
	{ title: 'JTBC2', href: 'http://jtbc2.joins.com/' },
	{ title: 'JTBC GOLF&SPORTS', href: 'http://jtbcgolfnsports.joins.com/' },
	{ title: 'JTBC4', href: 'http://jtbc4.joins.com/' },
	{ title: 'JTBC GOLF', href: 'http://www.jtbcgolf.com/' },
	{ title: 'SLL', href: 'https://www.sll.co.kr/' },
]
const multiplexItems = [
	{ title: '메가박스', href: 'http://www.megabox.co.kr/' },
	{ title: '필름 소사이어티', href: 'https://www.megabox.co.kr/curation/filmsociety' },
	{ title: '클래식 소사이어티', href: 'https://www.megabox.co.kr/movie/classic' },
	{ title: '휘닉스 호텔앤드리조트', href: 'https://phoenixhnr.co.kr/page/main' },
	{ title: '휘닉스 평창', href: 'https://phoenixhnr.co.kr/page/main/pyeongchang' },
	{ title: '휘닉스 섭지코지', href: 'https://phoenixhnr.co.kr/page/main/jeju' },
]
const magazineItems = [
	{ title: '월간중앙', href: 'https://m-joongang.com' },
	// { title: '월간중앙', href: 'http://jmagazine.joins.com/monthly' },
	// { title: '이코노미스트', href: 'http://jmagazine.joins.com/economist' },
	{ title: '포브스코리아', href: 'http://jmagazine.joins.com/forbes' },
	{ title: '중앙북스', href: 'https://jbooks.joins.com/' },
	{ title: '엘르', href: 'http://www.elle.co.kr/' },
	{ title: '바자', href: 'http://harpersbazaar.co.kr/' },
	{ title: '코스모폴리탄', href: 'http://www.cosmopolitan.co.kr/' },
	{ title: '에스콰이어', href: 'http://esquirekorea.co.kr/' },
]
const specializedItems = [
	{ title: '헬스미디어', href: 'http://jhealthmedia.com/' },
	{ title: '차이나랩', href: 'https://blog.naver.com/china_lab' },
	{ title: '영어의 신', href: 'http://jdphone.com/' },
]
const serviceItems = [
	{ title: '썰리', href: 'http://ssully.joins.com/' },
	{ title: 'fol:in', href: 'https://folin.co/' },
	{ title: 'JTBC NOW', href: 'http://jtbcnow.com/' },
	{ title: 'JTBC NEWS', href: 'https://play.google.com/store/apps/details?id=com.jtbc.news' },
	{ title: 'OOH MEDIA', href: 'http://oohmedia.kr/' },
	{ title: '중앙멤버십', href: 'https://jmembership.joins.com/' },
]
const BrandMenuList = () => {
	return (
		<Grid container>
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="신문" items={newsPaperItems} />
			</Grid>
			<UIDivider orientation="vertical" sx={{ background: color.colGray2 }} />
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="방송" items={broadcastItems} />
			</Grid>
			<UIDivider orientation="vertical" sx={{ background: color.colGray2 }} />
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="멀티플렉스&레저" items={multiplexItems} />
			</Grid>
			<UIDivider orientation="vertical" sx={{ background: color.colGray2 }} />
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="매거진&출판" items={magazineItems} />
			</Grid>
			<UIDivider orientation="vertical" sx={{ background: color.colGray2 }} />
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="전문 콘텐트" items={specializedItems} />
			</Grid>
			<UIDivider orientation="vertical" sx={{ background: color.colGray2 }} />
			<Grid
				xs={2}
				sx={{
					paddingRight: pxToRem(24),
					'&:not(:first-child)': {
						paddingLeft: { lg: pxToRem(24), xl: pxToRem(31) },
					},
				}}
			>
				<BrandMenuItem title="서비스" items={serviceItems} />
			</Grid>
		</Grid>
	)
}

export default BrandMenuList
