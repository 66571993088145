'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { theme } from '@ui/style'
import { pxToRem } from '@ui/style/muiTheme'
import NavMenu, { NavMenuProps } from './menu'
import { JTBCLogo, JTBCLogoArea, NavLayout, NEWSLogo } from './styled'
import NavUtils, { NavUtilsProps } from './utils'

export type NavBoxProps = NavUtilsProps & {
	menuPc: NavMenuProps['menu']
	isApp: boolean
}
const NavBox = ({ menuTop, menuPc, snsList, isApp, auth }: NavBoxProps) => {
	const { darkMode } = useDarkModeControl()

	return (
		<NavLayout component="nav">
			<UIFlex
				direction="row"
				alignItems="center"
				className="header__navWrapper"
				sx={{
					minWidth: '0',
					height: pxToRem(48),
					[theme.breakpoints.up('md')]: {
						height: pxToRem(80),
					},
					[theme.breakpoints.up('lg')]: {
						alignItems: 'flex-end',
						'&>*:not(:first-child)': {
							marginLeft: pxToRem(28),
						},
					},
					[theme.breakpoints.up('xl')]: {
						'&>*:not(:first-child)': {
							marginLeft: pxToRem(32),
						},
					},
					'.set-sticky &': {
						alignItems: 'center',
						height: pxToRem(52),
						[theme.breakpoints.up('md')]: {
							height: pxToRem(84),
						},
						[theme.breakpoints.up('lg')]: {
							alignItems: 'center',
						},
					},
				}}
			>
				<JTBCLogoArea className="header__logoArea">
					<JTBCLogo
						href={isApp ? '/' : process.env.NEXT_PUBLIC_JTBC_URL}
						darkMode={darkMode}
						className="header__logo"
					>
						<UIText readonly>JTBC</UIText>
					</JTBCLogo>
					<NEWSLogo href="/" darkMode={darkMode} className="header__logo">
						<UIText readonly>NEWS</UIText>
					</NEWSLogo>
				</JTBCLogoArea>
				<NavMenu darkMode={darkMode} menu={menuPc} />
			</UIFlex>
			<NavUtils menuTop={menuTop} snsList={snsList} isApp={isApp} auth={auth} />
		</NavLayout>
	)
}
export default NavBox
