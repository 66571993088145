import { theme } from '@ui/style'

export const Icon32Menu = ({ color, className }: SVGComponentProps) => {
	const iconColor = color || theme.color.colBlack
	return (
		<svg
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g id="Group">
				<rect
					id="Rectangle 4"
					x="0.45"
					y="0.549998"
					width="19.1"
					height="0.9"
					fill={iconColor}
					stroke={iconColor}
					strokeWidth="0.9"
				/>
				<rect
					id="Rectangle 6"
					x="0.45"
					y="7.55"
					width="19.1"
					height="0.9"
					fill={iconColor}
					stroke={iconColor}
					strokeWidth="0.9"
				/>
				<rect
					id="Rectangle 7"
					x="0.45"
					y="14.55"
					width="19.1"
					height="0.9"
					fill={iconColor}
					stroke={iconColor}
					strokeWidth="0.9"
				/>
			</g>
		</svg>
	)
}
