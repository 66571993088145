'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'
import SettingItem from './SettingItem'
import { DescText, ItemBox, TimeBox, TimeDesc, TimeTitle, UpdateText } from './styled'
import TimePicker from './TimePicker'
import { appInterface, useIsAppControl } from '@store/isApp'
import { useRecoilValue } from 'recoil'
import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { isUndefined } from '@utils/isUndefined'
import { dayjs } from '@utils/date'
import { useDeviceTokenMutation } from '@services/api/push/DeviceToken/mutation'
import useModal from '@components/layout/Modal'
import UILink from '@components/ui/UILink'
import { condition } from '@utils/condition'
import { useAuth } from '@hooks/useAuth'

interface SendAppStartEtiquetteTimeProps {
	startTime: string
}
interface SendAppEndEtiquetteTimeProps {
	endTime: string
}
interface SendDeviceNotificationStateProps {
	isOn: boolean
}

const SettingBody = () => {
	const nativeEvent = useRecoilValue(appInterface)
	const confirm = useModal()
	const [alarmUseYn, setAlarmUseYn] = useLocalStorage<boolean>('alarm_use_yn')
	const [newsAlarmYn, setNewsAlarmYn] = useLocalStorage<boolean>('news_alarm_yn')
	const [subNewsAlarmYn, setSubNewsAlarmYn] = useLocalStorage<boolean>('sub_news_alarm_yn')
	const [replyAlarmYn, setReplyAlarmYn] = useLocalStorage<boolean>('reply_alarm_yn')
	const [streamingAlarmYn, setStreamingAlarmYn] = useLocalStorage<boolean>('streaming_alarm_yn')
	const [adAlarmYn, setAdAlarmYn] = useLocalStorage<boolean>('ad_alarm_yn')
	const [enableAdDate, setEnableAdDate] = useLocalStorage<string>('enable_ad_date')
	const [etiquetteYn, setEtiquetteYn] = useLocalStorage<boolean>('etiquette_yn')
	const [etiquetteStartTime, setEtiquetteStartTime] =
		useLocalStorage<string>('etiquette_start_time')
	const [etiquetteEndTime, setEtiquetteEndTime] = useLocalStorage<string>('etiquette_end')
	const [networkYn, setNetworkYn] = useLocalStorage<boolean>('network_yn')
	const { appInfo, setAppInfo } = useIsAppControl()
	const { mutateAsync: updateDeviceToken } = useDeviceTokenMutation()
	const appLink = {
		A: process.env.NEXT_PUBLIC_APP_LINK_ANDROID,
		I: process.env.NEXT_PUBLIC_APP_LINK_IOS,
	}
	const calcVersion = (ver?: string) => {
		if (!ver) {
			return 0
		}
		const [major, minor, patch] = ver.split('.')
		return (
			parseInt(major ?? '0', 10) * 10000 +
			parseInt(minor ?? '0', 10) * 100 +
			parseInt(patch ?? '0', 10)
		)
	}
	const updateDeviceConfig = useCallback(
		(props: { [key: string]: string }) => {
			if (appInfo?.pushToken) {
				updateDeviceToken({
					deviceToken: appInfo.pushToken,
					...props,
				})
			}
		},
		[appInfo],
	)
	const changeAlarmUsed = (value: boolean) => {
		const useYN = value ? 'Y' : 'N'
		updateDeviceConfig({
			alarmUseYn: useYN,
			newsAlarmYn: useYN,
			subNewsAlarmYn: useYN,
			replyAlarmYn: useYN,
			streamingAlarmYn: useYN,
			...(!value ? { adAlarmYn: useYN } : {}),
		})
		setAlarmUseYn(value)
		setNewsAlarmYn(value)
		setSubNewsAlarmYn(value)
		setReplyAlarmYn(value)
		setStreamingAlarmYn(value)
		if (!value) {
			setAdAlarmYn(value)
		}
	}

	const changeNewsAlarmYn = (value: boolean) => {
		setNewsAlarmYn(value)
		updateDeviceConfig({ newsAlarmYn: value ? 'Y' : 'N' })
	}
	const changeSubNewsAlarmYn = (value: boolean) => {
		setSubNewsAlarmYn(value)
		updateDeviceConfig({ subNewsAlarmYn: value ? 'Y' : 'N' })
	}
	const changeReplyAlarmYn = (value: boolean) => {
		setReplyAlarmYn(value)
		updateDeviceConfig({ replyAlarmYn: value ? 'Y' : 'N' })
	}
	const changeStreamingAlarmYn = (value: boolean) => {
		setStreamingAlarmYn(value)
		updateDeviceConfig({ streamingAlarmYn: value ? 'Y' : 'N' })
	}
	const changeAdAlarmUsed = (value: boolean) => {
		setAdAlarmYn(value)
		setEnableAdDate(value ? dayjs().format('YY.MM.DD') : '')
		updateDeviceConfig({ adAlarmYn: value ? 'Y' : 'N' })
	}
	const changeEtiquetteYn = useCallback(
		(value: boolean) => {
			setEtiquetteYn(value)
			updateDeviceConfig({
				etiquetteYn: value ? 'Y' : 'N',
				...(value
					? {
							etiquetteStartTime,
							etiquetteEndTime,
					  }
					: {}),
			})
		},
		[etiquetteStartTime, etiquetteEndTime],
	)

	const confirmDeviceNotification = () => {
		confirm.open({
			title: '알림 접근 설정 안내',
			message: '알림 안내 기능이 꺼져 있습니다.\n시스템 설정에서 알림 접근을 허용해 주세요.',
			buttons: [
				{
					text: '확인',
					onClick: (close) => {
						close()
						nativeEvent({
							key: 'setCallDeviceNotificationSetting',
							value: null,
						})
					},
				},
			],
		})
	}

	const handleAlarmUsed = useCallback(
		(value: boolean) => {
			if (!value) {
				changeAlarmUsed(false)
			} else if (appInfo?.isPushNoti) {
				changeAlarmUsed(true)
			} else {
				confirmDeviceNotification()
			}
		},
		[appInfo?.isPushNoti],
	)
	const handleAdAlarmUsed = (value: boolean) => {
		if (!value) {
			changeAdAlarmUsed(false)
		} else {
			confirm.open({
				title: '이벤트, 광고성 정보 알림',
				message: '정보성 알림을 허용 하시겠습니까?',
				buttons: [
					{ text: '취소' },
					{
						text: '확인',
						onClick: (close) => {
							close()
							changeAdAlarmUsed(true)
						},
					},
				],
			})
		}
	}
	/** 에티켓 - 시작 시간 TimePicker 호출 */
	const handleStartTime = useCallback(() => {
		nativeEvent({
			key: 'startEtiquetteTime',
			value: {
				time: etiquetteStartTime,
			},
		})
	}, [nativeEvent])

	/** 에티켓 - 종료 시간 TimePicker 호출 */
	const handleEndTime = useCallback(() => {
		nativeEvent({
			key: 'endEtiquetteTime',
			value: {
				time: etiquetteEndTime,
			},
		})
	}, [nativeEvent])

	const confirmRemoveCache = useCallback(() => {
		confirm.open({
			message: '캐시 데이터를 삭제 하시겠습니까?',
			buttons: [
				{ text: '취소' },
				{
					text: '확인',
					onClick: (close) => {
						close()
						nativeEvent({
							key: 'removeCacheData',
							value: null,
						})
					},
				},
			],
		})
	}, [nativeEvent])

	/** 에티켓 - 시작 시간 전달 */
	const sendAppStartEtiquetteTime = useCallback(
		((event: CustomEvent<SendAppStartEtiquetteTimeProps>) => {
			setEtiquetteStartTime(event.detail.startTime)
			updateDeviceConfig({
				etiquetteStartTime: event.detail.startTime,
			})
		}) as EventListener,
		[updateDeviceConfig],
	)
	/** 에티켓 - 종료 시간 전달 */
	const sendAppEndEtiquetteTime = useCallback(
		((event: CustomEvent<SendAppEndEtiquetteTimeProps>) => {
			setEtiquetteEndTime(event.detail.endTime)
			updateDeviceConfig({
				etiquetteEndTime: event.detail.endTime,
			})
		}) as EventListener,
		[updateDeviceConfig],
	)
	/** APP System 알림 권한 상태 */
	const sendDeviceNotificationState = ((event: CustomEvent<SendDeviceNotificationStateProps>) => {
		setAppInfo({
			isPushNoti: event.detail.isOn,
		})
	}) as EventListener

	useEffect(() => {
		if (
			(isUndefined(alarmUseYn) && !isUndefined(appInfo?.isPushNoti)) ||
			appInfo?.isPushNoti === false
		) {
			changeAlarmUsed(appInfo?.isPushNoti)
		}
	}, [alarmUseYn, appInfo?.isPushNoti])

	useEffect(() => {
		if (!etiquetteStartTime) {
			setEtiquetteStartTime('23:00')
		}
		if (!etiquetteEndTime) {
			setEtiquetteEndTime('07:00')
		}
	}, [etiquetteStartTime, etiquetteEndTime])

	useEffect(() => {
		window.addEventListener('sendAppStartEtiquetteTime', sendAppStartEtiquetteTime)
		window.addEventListener('sendAppEndEtiquetteTime', sendAppEndEtiquetteTime)
		window.addEventListener('sendDeviceNotificationState', sendDeviceNotificationState)
		return () => {
			window.removeEventListener('sendAppStartEtiquetteTime', sendAppStartEtiquetteTime)
			window.removeEventListener('sendAppEndEtiquetteTime', sendAppEndEtiquetteTime)
			window.removeEventListener('sendDeviceNotificationState', sendDeviceNotificationState)
		}
	}, [])

	const { isLogin: useAuthIsLogin, login, logout } = useAuth()
	const [isClient, setIsClient] = useState(false)
	const handleLogout = () => {
		confirm.open({
			message: '로그아웃 하시겠습니까?',
			buttons: [
				{ text: '취소' },
				{
					text: '확인',
					onClick: (close) => {
						close()
						logout()
					},
				},
			],
		})
	}

	useEffect(() => {
		setIsClient(true)
	}, [])
	const loginStatusText = condition(useAuthIsLogin, '로그아웃', '로그인')

	// 로그인/로그아웃 처리 함수
	const handleLoginOrLogout = () => {
		condition(useAuthIsLogin, handleLogout, login)
	}

	return (
		<UIFlex
			flexGrow={1}
			overflow="auto"
			py={pxToRem(12)}
			px={pxToRem(20)}
			sx={{
				overscrollBehavior: 'contain',
				'&> *:not(:first-child)': { marginTop: pxToRem(32) },
			}}
		>
			<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}>
				<UIText
					fontSize={pxToRem(15)}
					lineHeight={pxToRem(22)}
					fontWeight={500}
					color={color.colGray5}
				>
					알림 설정
				</UIText>
				<UIFlex alignItems="flex-end">
					<ItemBox>
						<SettingItem
							title="알림 수신 설정"
							checked={alarmUseYn ?? false}
							onChange={handleAlarmUsed}
						/>
					</ItemBox>
					{alarmUseYn && (
						<>
							<ItemBox
								sx={{
									width: `calc(100% - ${pxToRem(16)})`,
								}}
							>
								<SettingItem
									title="뉴스 속보"
									isSub
									checked={newsAlarmYn ?? false}
									onChange={changeNewsAlarmYn}
								/>
							</ItemBox>
							<ItemBox
								sx={{
									width: `calc(100% - ${pxToRem(16)})`,
								}}
							>
								<SettingItem
									title="구독 뉴스"
									isSub
									checked={subNewsAlarmYn ?? false}
									onChange={changeSubNewsAlarmYn}
								/>
							</ItemBox>
							<ItemBox
								sx={{
									width: `calc(100% - ${pxToRem(16)})`,
								}}
							>
								<SettingItem
									title="댓글, 답글"
									isSub
									checked={replyAlarmYn ?? false}
									onChange={changeReplyAlarmYn}
								/>
							</ItemBox>
							<ItemBox
								sx={{
									width: `calc(100% - ${pxToRem(16)})`,
								}}
							>
								<SettingItem
									title="방송 시작"
									isSub
									checked={streamingAlarmYn ?? false}
									onChange={changeStreamingAlarmYn}
								/>
							</ItemBox>
							<ItemBox
								sx={{
									width: `calc(100% - ${pxToRem(16)})`,
								}}
							>
								<SettingItem
									title="이벤트, 광고성 정보 알림"
									desc={
										adAlarmYn && enableAdDate
											? `마케팅 정보 수신 동의 ${enableAdDate}`
											: undefined
									}
									isSub
									checked={adAlarmYn ?? false}
									onChange={handleAdAlarmUsed}
								/>
							</ItemBox>
						</>
					)}
					<ItemBox>
						<SettingItem
							title="에티켓 시간 설정"
							checked={etiquetteYn ?? false}
							onChange={changeEtiquetteYn}
						/>
						{etiquetteYn && (
							<UIFlex
								mt={pxToRem(16)}
								sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}
							>
								<TimeBox>
									<TimeTitle>시작 시간</TimeTitle>
									<TimeDesc>
										<TimePicker
											time={etiquetteStartTime}
											onClick={handleStartTime}
										/>
									</TimeDesc>
								</TimeBox>
								<TimeBox>
									<TimeTitle>종료 시간</TimeTitle>
									<TimeDesc>
										<TimePicker
											time={etiquetteEndTime}
											onClick={handleEndTime}
										/>
									</TimeDesc>
								</TimeBox>
							</UIFlex>
						)}
					</ItemBox>
				</UIFlex>
			</UIFlex>
			<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}>
				<UIText
					fontSize={pxToRem(15)}
					lineHeight={pxToRem(22)}
					fontWeight={500}
					color={color.colGray5}
				>
					앱 설정
				</UIText>
				<UIFlex>
					<ItemBox>
						<SettingItem
							title="모바일 데이터 LTE/5G 사용"
							checked={networkYn ?? false}
							onChange={setNetworkYn}
						/>
					</ItemBox>
					<ItemBox>
						<SettingItem
							title="캐시 데이터 삭제"
							noSwitch
							component="button"
							onClick={confirmRemoveCache}
						/>
					</ItemBox>
					<ItemBox>
						<SettingItem
							title={`버전정보 (${appInfo?.appVersion})`}
							noSwitch
							rightSlot={
								calcVersion(appInfo?.appVersion) >=
								calcVersion(appInfo?.adminVersion) ? (
									<UpdateText>최신 버전입니다</UpdateText>
								) : (
									<UILink
										href={
											['A', 'I'].includes(appInfo?.DType as string)
												? appLink[appInfo?.DType as 'A' | 'I']
												: '#'
										}
									>
										<UIFlex
											flexDirection="row"
											alignItems="center"
											sx={{
												path: {
													stroke: color.colGray4,
												},
											}}
										>
											<UpdateText>업데이트 하기</UpdateText>
											<IconGoIcons size="sm" />
										</UIFlex>
									</UILink>
								)
							}
						/>
					</ItemBox>
				</UIFlex>
			</UIFlex>
			<UIFlex sx={{ '&> *:not(:first-child)': { marginTop: pxToRem(8) } }}>
				<UIText
					fontSize={pxToRem(15)}
					lineHeight={pxToRem(22)}
					fontWeight={500}
					color={color.colGray5}
				>
					계정 관리
				</UIText>
				<UIFlex>
					<ItemBox>
						<SettingItem
							title={loginStatusText}
							noSwitch
							component="button"
							onClick={handleLoginOrLogout}
						/>
					</ItemBox>
				</UIFlex>
			</UIFlex>
		</UIFlex>
	)
}

export default SettingBody
