import { ApiResultVoVoid } from '@schemas/auth'
import { fetchAPI } from '@utils/http/apiRequest'

type UseYN = 'Y' | 'N'
export type DeviceTokenReqModel = {
	deviceToken: string
	dtype?: 'IOS' | 'AOS' | 'WEB'
	alarmUseYn?: UseYN
	newsAlarmYn?: UseYN
	subNewsAlarmYn?: UseYN
	replyAlarmYn?: UseYN
	streamingAlarmYn?: UseYN
	adAlarmYn?: UseYN
	etiquetteYn?: UseYN
	etiquetteStartTime?: string
	etiquetteEndTime?: string
}

export type DeviceTokenResponse = CommonResponse<ApiResultVoVoid>

const path = '/restapi/v1/post/news/device-token-type2'
const queryKeyString = (props?: DeviceTokenReqModel) => ['@device-token', props] as const

const getQuery = async (props: DeviceTokenReqModel, cookieHeader?: string) => {
	const {
		deviceToken,
		dtype,
		alarmUseYn,
		newsAlarmYn,
		subNewsAlarmYn,
		replyAlarmYn,
		streamingAlarmYn,
		adAlarmYn,
		etiquetteYn,
		etiquetteStartTime,
		etiquetteEndTime,
	} = props
	const params = new URLSearchParams({
		...(deviceToken ? { deviceToken: String(deviceToken) } : {}),
		...(dtype ? { dtype: String(dtype) } : {}),
		...(alarmUseYn ? { alarmUseYn: String(alarmUseYn) } : {}),
		...(newsAlarmYn ? { newsAlarmYn: String(newsAlarmYn) } : {}),
		...(subNewsAlarmYn ? { subNewsAlarmYn: String(subNewsAlarmYn) } : {}),
		...(replyAlarmYn ? { replyAlarmYn: String(replyAlarmYn) } : {}),
		...(streamingAlarmYn ? { streamingAlarmYn: String(streamingAlarmYn) } : {}),
		...(adAlarmYn ? { adAlarmYn: String(adAlarmYn) } : {}),
		...(etiquetteYn ? { etiquetteYn: String(etiquetteYn) } : {}),
		...(etiquetteStartTime ? { etiquetteStartTime: String(etiquetteStartTime) } : {}),
		...(etiquetteEndTime ? { etiquetteEndTime: String(etiquetteEndTime) } : {}),
	})
	return fetchAPI({ path, method: 'POST', params, cookieHeader })
}

export const queryDeviceToken = (props: DeviceTokenReqModel, cookieHeader?: string) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const deviceTokenMutationProps = (cookieHeader?: string) => ({
	mutationKey: queryKeyString(),
	mutationFn: (props: DeviceTokenReqModel) => getQuery(props, cookieHeader),
})
