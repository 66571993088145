'use client'

import UIFlex from '@components/ui/UIFlex'
import UISwitch from '@components/ui/UISwitch'
import UIText, { UITextProps } from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { ChangeEvent } from 'react'

interface SettingItemProps {
	title: string
	desc?: string
	noSwitch?: boolean
	rightSlot?: React.ReactNode
	isSub?: boolean
	component?: UITextProps['component']
	checked?: boolean
	onChange?: (checked: boolean) => void
	onClick?: () => void
}
const SettingItem = ({
	title,
	desc,
	noSwitch,
	rightSlot,
	isSub,
	component,
	checked,
	onChange,
	onClick,
}: SettingItemProps) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.target.checked)
	}
	return (
		<UIFlex
			flexDirection="row"
			alignItems="flex-start"
			justifyContent="space-between"
			onClick={onClick}
			{...(component && { component })}
			sx={{
				'button&': {
					marginTop: pxToRem(-16),
					marginBottom: pxToRem(-16),
					paddingTop: pxToRem(16),
					paddingBottom: pxToRem(16),
					width: '100%',
				},
				'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
				'.MuiSwitch-root': {
					alignSelf: 'flex-start',
				},
			}}
		>
			<Box>
				<UIText
					fontSize={pxToRem(16)}
					lineHeight={pxToRem(24)}
					fontWeight={isSub ? 500 : 600}
					lineClamp={1}
				>
					{title}
				</UIText>
				{desc && (
					<UIText
						fontSize={pxToRem(14)}
						lineHeight={pxToRem(22)}
						fontWeight={500}
						color={color.colGray4}
						mt={pxToRem(4)}
					>
						{desc}
					</UIText>
				)}
			</Box>
			{noSwitch ? (
				<Box>{rightSlot}</Box>
			) : (
				<UISwitch size="xl" checked={checked} onChange={handleChange} />
			)}
		</UIFlex>
	)
}

export default SettingItem
