'use client'

import UIFlex from '@components/ui/UIFlex'
import { Grid, styled } from '@mui/joy'
import UILink from '@components/ui/UILink'
import UIText, { UITextProps } from '@components/ui/UIText'
import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import { theme } from '@ui/style'
import { HierarchyMenuResponseVo } from '@schemas/non-auth'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { useCloseHeaderState } from '../../store/store'

interface MegaItemTitleProps extends UITextProps {
	darkMode?: boolean
}
export const MegaItemTitle = styled(UIText, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<MegaItemTitleProps>(({ darkMode }) => ({
	fontSize: pxToRem(15),
	fontWeight: 700,
	lineHeight: pxToRem(18),
	color: darkMode ? color.colWhite : color.colDarkBlue,

	[theme.breakpoints.up('md')]: {
		fontSize: pxToRem(14),
		lineHeight: pxToRem(22),
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: pxToRem(15),
	},
}))

interface MegaMenuItemProps {
	title: string
	linkUrlInfo?: HierarchyMenuResponseVo['linkUrlInfo']
	items: Array<HierarchyMenuResponseVo>
}

const MegaMenuItem = ({ title, linkUrlInfo, items }: MegaMenuItemProps) => {
	const { darkMode } = useDarkModeControl()
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')
	return (
		<UIFlex
			fontSize={0}
			sx={{
				'&>*:not(:first-child)': {
					marginTop: {
						xs: pxToRem(20),
						md: pxToRem(16),
					},
				},
			}}
		>
			<MegaItemTitle darkMode={darkMode}>
				{linkUrlInfo && linkUrlInfo.linkCode ? (
					<UILink
						link={linkUrlInfo}
						onClick={handleMegaMenuClose}
						sx={{
							fontSize: 'inherit',
							lineHeight: 'inherit',
						}}
					>
						{title}
					</UILink>
				) : (
					title
				)}
			</MegaItemTitle>
			<Grid
				container
				sx={{
					[theme.breakpoints.down('md')]: {
						'&>*:nth-of-type(n + 3)': {
							marginTop: pxToRem(20),
						},
						'&>*:nth-of-type(2n-1)': {
							paddingRight: pxToRem(8),
						},
						'&>*:nth-of-type(2n)': {
							paddingLeft: pxToRem(8),
						},
					},
					[theme.breakpoints.up('md')]: {
						'&>*:nth-of-type(n + 2)': {
							marginTop: pxToRem(14),
						},
					},
					[theme.breakpoints.up('lg')]: {
						'&>*:nth-of-type(n + 2)': {
							marginTop: pxToRem(16),
						},
					},
				}}
			>
				{items.map((item, index) => (
					<Grid key={item.menuIdx} xs={6} md={12}>
						<UILink link={item.linkUrlInfo} onClick={handleMegaMenuClose}>
							<UIText
								fontSize={{
									xs: pxToRem(14),
									md: pxToRem(13),
									lg: pxToRem(14),
								}}
								lineHeight={{
									xs: index > 1 ? pxToRem(18) : pxToRem(16),
									md: pxToRem(20),
								}}
								fontWeight={600}
								color={darkMode ? '#d6d6d6' : color.colBlack}
								sx={{
									wordBreak: 'break-all',
								}}
							>
								{item.menuName}
							</UIText>
						</UILink>
					</Grid>
				))}
			</Grid>
		</UIFlex>
	)
}

export default MegaMenuItem
