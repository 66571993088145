'use client'

import { forwardRef } from 'react'
import UISwitchRoot, { UISwitchState } from './UISwitchRoot'

const UISwitch = forwardRef<HTMLDivElement, UISwitchState>(
	({ size = 'md', color = 'default', ...props }, ref) => {
		return <UISwitchRoot ref={ref} ownerState={{ size, color }} {...props} />
	},
)

export default UISwitch
