import { NoticeResponseVo } from '@schemas/auth'

declare module '@utils/http/response' {
	interface AppResponseList {
		'/restapi/v1/get/notification/notice/list': NotificationNoticeListResponse
	}
}

export const notificationNoticeListQueryKey = '@notification/notice/list' as const

export type NoticeType = 'NEWS' | 'SUBSCRIBE' | 'ACTION' | 'LIVE_RESERVE'
export type NotificationNoticeListReqModel = {
	noticeType?: NoticeType
}
export type NotificationNoticeListResponse = CommonResponse<NoticeResponseVo[]>
