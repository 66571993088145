import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { ArticleRankViewReqModel, ArticleRankViewResponse, articleRankViewQueryKey } from './types'

const path = '/restapi/v1/get/article/rank-view'

export const initialArticleRankViewtReqArgs: ArticleRankViewReqModel = {
	pageSize: 5,
}

export const fetchArticleRankView = async (
	arg?: ArticleRankViewReqModel,
	cookieHeader?: string,
): Promise<ArticleRankViewResponse> => {
	const { pageSize, searchDate, articleListType } = arg ?? {}

	const params = new URLSearchParams({
		pageSize: String(pageSize ?? 20),
		...(searchDate ? { searchDate } : {}),
		...(articleListType ? { articleListType } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const initialArticleRankViewReqArgs: ArticleRankViewReqModel = {
	pageSize: 5,
}

export const queryArticleRankView = (props: ArticleRankViewReqModel, cookieHeader?: string) => ({
	queryKey: [...articleRankViewQueryKey, props ?? {}],
	queryFn: () => fetchArticleRankView(props, cookieHeader),
})

export const prefetchArticleRankView = (props: ArticleRankViewReqModel, cookieHeader?: string) =>
	createPrefetchQuery([...articleRankViewQueryKey, props ?? {}], () =>
		fetchArticleRankView(props, cookieHeader),
	)
