import { useDarkModeControl } from '@hooks/useDarkModeControl'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { IconGoIcons } from 'src/assets/icons/IconGoIcons'

import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import SearchRankArticleList from './SearchRankArticleList'

const MostReadArticlesSection = () => {
	const { darkMode } = useDarkModeControl()
	return (
		<UIFlex>
			<UIFlex
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				pb={{ xs: pxToRem(16), md: 0 }}
				mb={pxToRem(12)}
				borderBottom={{ xs: `1px solid ${darkMode ? '#303030' : color.colGray3}`, md: 0 }}
				sx={{
					'.goIcon-lg': {
						display: {
							xs: 'none',
							md: 'block',
						},
					},
					'.goIcon-md': {
						display: {
							xs: 'block',
							md: 'none',
						},
					},
				}}
			>
				<UIText
					fontSize={{ xs: pxToRem(20), md: pxToRem(22) }}
					lineHeight={{ xs: pxToRem(24), md: pxToRem(36) }}
					fontWeight={700}
					color={darkMode ? color.colGray1 : color.colBlack}
				>
					많이 본 기사
				</UIText>
				<UILink href="/rankNews">
					<IconGoIcons size="lg" isDark={darkMode} className="goIcon-lg" />
					<IconGoIcons size="md" isDark={darkMode} className="goIcon-md" />
				</UILink>
			</UIFlex>
			<SearchRankArticleList darkMode={darkMode} />
		</UIFlex>
	)
}

export default MostReadArticlesSection
