'use client'

import UIFlex from '@components/ui/UIFlex'
import { pxToRem } from '@ui/style/muiTheme'
import UIText from '@components/ui/UIText'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'
import Icon32Settings from 'src/assets/icons/Icon32Settings'
import { JTBCLogo, JTBCLogoArea, NEWSLogo } from '../../NavBox/styled'
import { useCloseHeaderState, useOpenHeaderState } from '../../store/store'
import { useIsAppControl } from '@store/isApp'

const MegaMenuMobileHeader = () => {
	const { isApp } = useIsAppControl()
	const { darkMode } = useDarkModeControl()
	const handleSettingOpen = useOpenHeaderState('settingOpen')
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')

	return (
		<UIFlex
			display={{ xs: 'flex', md: 'none' }}
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			pl={pxToRem(20)}
			pr={pxToRem(12)}
			flexShrink={0}
			height={pxToRem(48)}
		>
			<JTBCLogoArea>
				<JTBCLogo
					href={isApp ? '/' : process.env.NEXT_PUBLIC_JTBC_URL}
					darkMode={darkMode}
					onClick={handleMegaMenuClose}
				>
					<UIText readonly>JTBC</UIText>
				</JTBCLogo>
				<NEWSLogo href="/" darkMode={darkMode}>
					<UIText readonly>NEWS</UIText>
				</NEWSLogo>
			</JTBCLogoArea>
			<UIFlex
				flexDirection="row"
				sx={{
					'&>*:not(:first-child)': {
						marginLeft: pxToRem(12),
					},
				}}
			>
				{isApp && (
					<button type="button" onClick={handleSettingOpen}>
						<Icon32Settings color={darkMode ? color.colGray1 : color.colBlack} />
						<UIText readonly>설정</UIText>
					</button>
				)}
				<button type="button" onClick={handleMegaMenuClose}>
					<Icon32Close color={darkMode ? color.colGray1 : color.colBlack} />
					<UIText readonly>닫기</UIText>
				</button>
			</UIFlex>
		</UIFlex>
	)
}

export default MegaMenuMobileHeader
