import { useMutation } from '@tanstack/react-query'
import { fetchAPI } from '@utils/http/apiRequest'
import { NotificationNoticeRecentReadResponse, notificationNoticeRecentReadQueryKey } from './types'

const path = '/restapi/v1/put/notification/notice/recent-read'
export const getNotificationNoticeRecentRead = async (cookieHeader?: string) => {
	return fetchAPI({
		path,
		method: 'POST',
		cookieHeader,
	})
}

export const useNotificationNoticeRecentReadMutation = (cookieHeader?: string) => {
	const { data, ...rest } = useMutation<NotificationNoticeRecentReadResponse, Error>({
		mutationKey: [notificationNoticeRecentReadQueryKey],
		mutationFn: () => getNotificationNoticeRecentRead(cookieHeader),
	})
	return { data, ...rest }
}
