import { useQuery } from '@tanstack/react-query'
import { fetchAPI } from '@utils/http/apiRequest'
import { CommonUseQueryInput } from 'src/shared/types'
import {
	NotificationNoticeListReqModel,
	NotificationNoticeListResponse,
	notificationNoticeListQueryKey,
} from './types'

const path = '/restapi/v1/get/notification/notice/list'
export const getNotificationNoticeList = async (args: NotificationNoticeListReqModel) => {
	const params = new URLSearchParams()
	if (args.noticeType) {
		params.append('noticeType', args.noticeType)
	}

	return fetchAPI({ path, params })
}

export const useNotificationNoticeListQuery = (
	input?: CommonUseQueryInput<NotificationNoticeListResponse, NotificationNoticeListReqModel>,
) => {
	const { data, ...rest } = useQuery<NotificationNoticeListResponse>({
		queryKey: [notificationNoticeListQueryKey, JSON.stringify(input?.arg)],
		queryFn: async () => {
			const res = await getNotificationNoticeList(input?.arg || {})
			return res
		},
		...input?.opt,
	})
	return { data, ...rest }
}
