'use client'

import { styled, Box } from '@mui/joy'
import { SwiperSlide } from 'swiper/react'
import UILink from '@components/ui/UILink'

import NavButton from '@components/common/Carousel/NavButton'
import UIButton from '../UIButton'
import { fontSize, lineHeight } from 'styled-system'
import pxToRem from '@utils/pxToRem'
import color from '@ui/style/color.theme'
import { mediaQueries } from '@ui/style/mediaQuery.theme'

interface SlideProps {
	button?: string
	link?: string
}
export const SlideContainer = styled(Box)<SlideProps>(({ button, theme }) => ({
	...(button ? {} : { borderBottom: '1px solid', borderColor: theme.color.colGray3 }),
}))
export const SlideWrap = styled(Box)(({ theme }) => ({
	position: 'relative',
	margin: '0 auto',
	maxWidth: '100%',
	[theme.breakpoints.up('md')]: {
		maxWidth: `calc(${theme.breakpoint.md}px - ${theme.pxToRem(24)} * 2)`,
	},
	[theme.breakpoints.up('lg')]: {
		maxWidth: `calc(${theme.breakpoint.lg}px - ${theme.pxToRem(48)} * 2)`,
	},
	[theme.breakpoints.up('xl')]: {
		maxWidth: `calc(${theme.breakpoint.xl}px - ${theme.pxToRem(32)} * 2)`,
	},
}))

export const SlideItem = styled(SwiperSlide)<SlideProps>(({ button, theme }) => {
	return {
		width: 'auto',
		'&:not(:last-child)': {
			paddingRight: theme.pxToRem(button === 'button' ? 8 : 12),
			...(button !== 'button'
				? {
						[theme.breakpoints.up('md')]: {
							paddingRight: theme.pxToRem(16),
						},
				  }
				: {}),
		},
	}
})

const slideAnchorStyle = {
	display: 'flex',
	paddingTop: pxToRem(12),
	paddingRight: pxToRem(8),
	paddingBottom: pxToRem(10),
	paddingLeft: pxToRem(8),
	justifyContent: 'center',
	alignItems: 'center',
	borderBottom: '2px solid transparent',
	color: color.colGray4,
	flexShrink: 0,
	fontSize: pxToRem(16),
	lineHeight: pxToRem(22),
	fontWeight: 700,
	backgroundColor: 'unset',
	borderRadius: 0,
	[mediaQueries.over.md]: {
		fontSize: pxToRem(17),
	},
	[mediaQueries.over.lg]: {
		fontSize: pxToRem(18),
		lineHeight: pxToRem(24),
	},
	[mediaQueries.hover]: {
		'&:not([aria-selected="true"]):active, &:not([aria-selected="true"]):hover': {
			backgroundColor: '#F6F6F6',
			borderBottom: '2px solid',
			borderColor: color.colGray5,
			color: color.colGray5,
		},
	},
	'&[aria-selected="true"]': {
		backgroundColor: 'unset',
		borderBottomColor: color.colDarkBlue,
		color: color.colDarkBlue,
	},
}

export const SlideAnchor = styled(UILink)(({ theme }) => slideAnchorStyle)

export const SlideAnchorButton = styled(UIButton)(({ theme }) => slideAnchorStyle)

export const TabNavButtonGroup = styled(Box)``
export const TabNavButton = styled(NavButton)`
	position: absolute;
	&:disabled {
		display: none;
	}
`
