const Icon32Delete = ({ className }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			className={className}
		>
			<rect x="8.99951" y="9.5" width="14" height="16" stroke="#242424" strokeWidth="1.8" />
			<line
				x1="17.8995"
				y1="13.5"
				x2="17.8995"
				y2="21.5"
				stroke="#242424"
				strokeWidth="1.8"
			/>
			<line
				x1="13.8995"
				y1="13.5"
				x2="13.8995"
				y2="21.5"
				stroke="#242424"
				strokeWidth="1.8"
			/>
			<line x1="25.9995" y1="9.4" x2="5.99951" y2="9.4" stroke="#242424" strokeWidth="1.8" />
			<line x1="20.2495" y1="5.4" x2="11.7495" y2="5.4" stroke="#242424" strokeWidth="1.8" />
		</svg>
	)
}

export default Icon32Delete
