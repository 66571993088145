'use client'

import { useDarkModeControl } from '@hooks/useDarkModeControl'

import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'

import ContentCard, { ContentCardProps } from '@components/content/ContentCard'

interface SearchRankArticleItemProps {
	content: ContentCardProps['content']
}
const SearchRankArticleItem = ({ content }: SearchRankArticleItemProps) => {
	const { darkMode } = useDarkModeControl()
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', md: 'column' },
			layoutGap: pxToRem(16),
			layoutSx: {
				alignItems: 'flex-start',
				img: {
					height: { xs: 'auto', md: pxToRem(126), lg: pxToRem(162), xl: pxToRem(137) },
				},
			},
		},
		infoConfig: {
			infoSx: {
				'.info-title': {
					fontSize: pxToRem(16),
					lineHeight: pxToRem(22),
					color: darkMode ? color.colGray1 : '',
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: {
				xs: pxToRem(120),
				md: pxToRem(224),
				lg: pxToRem(288),
				xl: pxToRem(245.33),
			},
			thumbHeight: {
				xs: pxToRem(68),
				md: pxToRem(126),
				lg: pxToRem(162),
				xl: pxToRem(137),
			},
			badgeInset: pxToRem(6),
		},
	}
	return (
		<ContentCard
			content={content}
			layoutConfig={cardProps.layoutConfig}
			infoConfig={cardProps.infoConfig}
			thumbnailConfig={cardProps.thumbnailConfig}
		/>
	)
}

export default SearchRankArticleItem
