import React from 'react'

type Icon32ArrowProps = {
	width?: number
	height?: number
	color?: string
}

const Icon32Arrow = ({ width = 32, height = 32, color = '#242424' }: Icon32ArrowProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="icon-32-arrow"
		>
			<g id="Icons / 32 / Arrow">
				<path
					id="Vector 191"
					d="M20.5 7L11.5 16L20.5 25"
					stroke={color}
					strokeWidth="2.5"
				/>
			</g>
		</svg>
	)
}

export default Icon32Arrow
