const IconNewsflash = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#F6F6F6" />
			<rect
				x="0.25"
				y="0.25"
				width="39.5"
				height="39.5"
				rx="19.75"
				stroke="black"
				strokeOpacity="0.1"
				strokeWidth="0.5"
			/>
			<path
				d="M25.5721 12H16.8344L14 22.2488H18.8707L17.1569 30L22.3313 24.3158L28 18.2182H20.9141L25.5721 12Z"
				stroke="#777777"
				strokeWidth="1.5"
			/>
		</svg>
	)
}

export default IconNewsflash
