import { TIsLiveResModel } from '@services/api/Live/IsLive'
import { useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

interface IsLiveState {
	isLive?: boolean
}

export const isLiveState = atom<IsLiveState>({
	key: '#isLive',
	default: {
		isLive: undefined,
	},
})

export const useIsLiveControl = () => {
	const [state, setState] = useRecoilState(isLiveState)

	const setIsLive = useCallback(
		(value?: TIsLiveResModel) => {
			setState({ isLive: value === 'LIVE' })
			// if (value ) {
			// 	setState({ isLive: isLiveState })
			// } else {
			// 	setState({ isLive: false })
			// }
		},
		[setState],
	)

	return { isLive: state.isLive, setIsLive }
}

export const useSetIsLive = () => {
	const setState = useSetRecoilState(isLiveState)
	return (value?: TIsLiveResModel) => {
		setState({ isLive: value === 'LIVE' })
		// if (value) {
		// 	setState({ isLive: value })
		// } else {
		// 	setState({ isLive: false })
		// }
	}
}

export const useGetIsLive = () => {
	const state = useRecoilValue(isLiveState)
	return state.isLive
}
