'use client'

import useModal from '@components/layout/Modal'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { Box, BoxProps, Grid, styled } from '@mui/joy'
import { useAuthControl } from '@store/auth'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import Icon32MegaLive from 'src/assets/icons/Icon32MegaLive'
import Icon32MegaMy from 'src/assets/icons/Icon32MegaMy'
import Icon32MegaSearch from 'src/assets/icons/Icon32MegaSearch'
import { useCloseHeaderState, useOpenHeaderState } from '../../store/store'
import Lottie from 'react-lottie-player'
import tabBarLiveActiveJson from 'src/assets/icons/lottie/IconTabBarLiveActive.json'
import useIsMounted from '@hooks/useIsMounted'
import { useGetIsLive } from '@store/isLive'

const UtilBox = styled(UIFlex)({
	alignItems: 'center',
	paddingTop: pxToRem(16),
	paddingBottom: pxToRem(16),
	'&>*:not(:first-child)': {
		marginTop: pxToRem(4),
	},
})
const UtilText = styled(UIText)({
	fontSize: pxToRem(13),
	lineHeight: pxToRem(16),
	fontWeight: 600,
})
interface UtilDividerProps {
	darkMode?: boolean
}
const UtilDivider = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'darkMode',
})<UtilDividerProps>(({ darkMode }) => ({
	height: pxToRem(52),
	width: '1px',
	background: darkMode ? '#303030' : color.colGray2,
	marginLeft: '-1px',
}))

type MypageButtonProps = Omit<BoxProps, 'ref'> & { darkMode: boolean }

const MypageButton = ({ darkMode, ...props }: MypageButtonProps) => {
	return (
		<UtilBox {...props} sx={{ width: '100%' }}>
			<Icon32MegaMy color={darkMode ? color.colGray1 : color.colBlack} />
			<UtilText color={darkMode ? color.colGray1 : color.colBlack}>마이페이지</UtilText>
		</UtilBox>
	)
}

const MegaMenuMobileUtilities = () => {
	const { darkMode } = useDarkModeControl()
	const { auth } = useAuthControl()
	const handleSearchOpen = useOpenHeaderState('searchOpen')
	const handleMegaMenuClose = useCloseHeaderState('megaMenuOpen')

	const { login: openLoginModal } = useModal()

	const isMounted = useIsMounted()
	const isLive = useGetIsLive()

	return (
		<Grid container columnSpacing={pxToRem(24)} alignItems="center">
			<Grid xs={4}>
				{auth ? (
					<UILink href="/mypage" display="block" onClick={handleMegaMenuClose}>
						<MypageButton darkMode={darkMode} />
					</UILink>
				) : (
					<MypageButton
						darkMode={darkMode}
						component="button"
						onClick={() =>
							openLoginModal({ targetUrl: `${window.location.origin}/mypage` })
						}
					/>
				)}
			</Grid>
			<UtilDivider darkMode={darkMode} />
			<Grid xs={4}>
				<button type="button" onClick={handleSearchOpen} style={{ width: '100%' }}>
					<UtilBox>
						<Icon32MegaSearch color={darkMode ? color.colGray1 : color.colBlack} />
						<UtilText color={darkMode ? color.colGray1 : color.colBlack}>검색</UtilText>
					</UtilBox>
				</button>
			</Grid>
			<UtilDivider darkMode={darkMode} />
			<Grid xs={4}>
				<UILink href="/live" display="block" onClick={handleMegaMenuClose}>
					<UtilBox>
						{isMounted && isLive ? (
							<Box width={pxToRem(32)} height={pxToRem(32)}>
								<Lottie loop animationData={tabBarLiveActiveJson} play />
							</Box>
						) : (
							<Icon32MegaLive color={darkMode ? color.colGray1 : color.colBlack} />
						)}
						<UtilText color={darkMode ? color.colGray1 : color.colBlack}>LIVE</UtilText>
					</UtilBox>
				</UILink>
			</Grid>
		</Grid>
	)
}

export default MegaMenuMobileUtilities
