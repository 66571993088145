'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import Icon32Arrow from 'src/assets/icons/Icon32Arrow'

interface SettingHeaderProps {
	onClose: () => void
}
const SettingHeader = ({ onClose }: SettingHeaderProps) => {
	return (
		<UIFlex height={pxToRem(48)} px={pxToRem(44)} flexShrink={0} justifyContent="center">
			<Box
				position="absolute"
				left={pxToRem(12)}
				top={pxToRem(8)}
				sx={{
					'.icon-32-arrow': {
						path: {
							strokeWidth: 1.8,
						},
					},
				}}
			>
				<button type="button" onClick={onClose}>
					<Icon32Arrow />
					<UIText readonly>뒤로가기</UIText>
				</button>
			</Box>
			<UIText
				fontSize={pxToRem(18)}
				lineHeight={pxToRem(32)}
				fontWeight={600}
				textAlign="center"
				lineClamp={1}
			>
				설정
			</UIText>
		</UIFlex>
	)
}

export default SettingHeader
