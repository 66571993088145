'use client'

import UIDivider from '@components/ui/UIDivider'
import { Grid } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { Fragment } from 'react'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import HighlightMenu from './HighlightMenu'
import MegaMenuItem from './MegaMenuItem'
import EtcMenuItem from './EtcMenuItem'
import { HierarchyMenuResponseWrapperVo } from '@schemas/non-auth'

export interface MegaMenuListProps {
	menu: HierarchyMenuResponseWrapperVo
}
const MegaMenuList = ({ menu }: MegaMenuListProps) => {
	const { darkMode } = useDarkModeControl()
	const { highlightList, topMenusWithSubMenus, topMenusWithoutSubMenus } = menu
	const pcHighlightList = highlightList?.filter((item) => item.isPcHighlight)
	const moHighlightList = highlightList?.filter((item) => item.isMoHighlight)
	const gridSize =
		12 /
		(((pcHighlightList?.length ?? 0) > 0 ? 1 : 0) +
			(topMenusWithSubMenus?.length ?? 0) +
			((topMenusWithoutSubMenus?.length ?? 0) > 0 ? 1 : 0))
	return (
		<Grid
			container
			py={{ md: pxToRem(16), lg: pxToRem(24) }}
			sx={{
				'&>*:not(:last-child)': {
					marginBottom: {
						xs: pxToRem(20),
						md: 0,
					},
				},
			}}
		>
			{(pcHighlightList?.length ?? 0) > 0 && (
				<UIDivider
					orientation={{ xs: 'horizontal', md: 'vertical' }}
					sx={{
						display: {
							xs: moHighlightList && moHighlightList.length > 0 ? 'block' : 'none',
							md: 'none',
							lg: 'block',
						},
						background: darkMode ? '#303030' : color.colGray2,
					}}
				/>
			)}
			{highlightList && highlightList.length > 0 && (
				<Grid
					display={{
						xs: moHighlightList && moHighlightList.length > 0 ? 'block' : 'none',
						md: pcHighlightList && pcHighlightList.length > 0 ? 'block' : 'none',
					}}
					xs={12}
					md={gridSize}
					pl={{ md: pxToRem(24), lg: pxToRem(32) }}
					pr={{ md: pxToRem(16), lg: pxToRem(24), xl: pxToRem(32) }}
				>
					<HighlightMenu menu={highlightList} />
				</Grid>
			)}
			<UIDivider
				orientation={{ xs: 'horizontal', md: 'vertical' }}
				sx={{
					display: pcHighlightList ? 'block' : { md: 'none', lg: 'block' },
					background: darkMode ? '#303030' : color.colGray2,
				}}
			/>
			{topMenusWithSubMenus?.map((item) => (
				<Fragment key={`menu-${item.menuIdx}`}>
					<Grid
						xs={12}
						md={gridSize}
						pl={{ md: pxToRem(24), lg: pxToRem(32) }}
						pr={{ md: pxToRem(16), lg: pxToRem(24), xl: pxToRem(32) }}
					>
						<MegaMenuItem
							title={item.menuName}
							linkUrlInfo={item.linkUrlInfo}
							items={item.lowerMenu}
						/>
					</Grid>
					<UIDivider
						orientation={{ xs: 'horizontal', md: 'vertical' }}
						sx={{ background: darkMode ? '#303030' : color.colGray2 }}
					/>
				</Fragment>
			))}
			{topMenusWithoutSubMenus && topMenusWithoutSubMenus.length > 0 && (
				<>
					<Grid
						xs={12}
						md={gridSize}
						pl={{ md: pxToRem(24), lg: pxToRem(32) }}
						pr={{ md: pxToRem(24) }}
					>
						<EtcMenuItem menu={topMenusWithoutSubMenus} />
					</Grid>
					<UIDivider
						orientation={{ xs: 'horizontal', md: 'vertical' }}
						sx={{
							display: { md: 'none', lg: 'block' },
							background: darkMode ? '#303030' : color.colGray2,
						}}
					/>
				</>
			)}
		</Grid>
	)
}

export default MegaMenuList
